<template>
<v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px; min-width: 300px">

    <div class="Absolute-Center">
        <div class="logo2">

        </div>
        <div class="titleproduct" style="margin-bottom:20px;">
            <v-img alt="Emt logo" class=" " contain src="@/assets/emt-logo-small.png" transition="scale-transition" width="120" />
        </div>
        <a style="color:#879097;margin-top:8px;" id="hdtool-link" href="" onclick="javascript:return false ;" class="href">https://smartinout.eu/</a>

        <div class="version">
            <span class="version"> Versione 1.0.0</span>
            <br>
            Powered and Designed by LC-Service SRL
        </div>

        <div class="policy">

            <a style="color:#879097;" id="privacy-link" href="" onclick="javascript:return false ;" class="href">Privacy
                policy</a>
            <br><br>

            <a style="color:#879097;" id="footer-link" href="" onclick="javascript:return false ;" class="href">https://lc-service.it</a>
        </div>

    </div>

</v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";

import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        this.$root.$children[0].checkLogout();

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];

                try {
                    window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                } catch (error) {
                    window.open("https://lc-service.it", "_new");
                }

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];

                try {
                    window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                } catch (error) {
                    window.open("https://emtool.eu/emt-privacy/", "_new");
                }

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];

                try {
                    window.flutter_inappwebview.callHandler('openHdHandler', ...args);
                    //window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                } catch (error) {
                    window.open("https://smartinout.eu/", "_new");
                }

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {
                case "btn_add_tms":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });

        this.setupButtons();
    },
    data: () => ({
        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
            (v && v.length <= 16) ||
            "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
            (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {
        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    // {
                    //     text: "Dashboard",
                    //     icon: "mdi-home-circle",
                    //     link: "/dash",
                    //     id: "btn_exit",
                    //     disabled: false,
                    // },
                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },
        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}
</style>
