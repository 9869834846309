<template>
    <div>
        <p class="error" v-if="noFrontCamera">
            You don't seem to have a front camera on your device
        </p>

        <p class="error" v-if="noRearCamera">
            You don't seem to have a rear camera on your device
        </p>

        <qrcode-stream :camera="camera" @init="onInit">
            <button @click="switchCamera">
                <img :src="$withBase('/camera-switch.svg')" alt="switch camera">
            </button>
        </qrcode-stream>

        <p class="decode-result">Last result: <b>{{ result }}</b></p>

        <div class="mainwrapper">
            <div class="centerSquare">

                <div v-bind:class="{ 'overlayboxpending': processing, overlaybox: true }">
                    <div class="overlay-element top-left"></div>
                    <div class="overlay-element top-right"></div>
                    <div class="overlay-element bottom-left"></div>
                    <div class="overlay-element bottom-right"></div>
                </div>

            </div>

            <div class="badgelogo">
                <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/emt-logo-small.png"
                    transition="scale-transition" width="80" />
            </div>

            <div class="rowhour">
                <div class="hour">
                    {{ hour }}
                </div>

                <div class="rowWeek">

                    <div v-bind:class="{ active: giorno == 'LUN', pizza: true }">LUN</div>
                    <div v-bind:class="{ active: giorno == 'MAR' }">MAR</div>
                    <div v-bind:class="{ active: giorno == 'MER' }">MER</div>
                    <div v-bind:class="{ active: giorno == 'GIO' }">GIO</div>
                    <div v-bind:class="{ active: giorno == 'VEN' }">VEN</div>
                    <div v-bind:class="{ active: giorno == 'SAB' }">SAB</div>
                    <div v-bind:class="{ active: giorno == 'DOM' }">DOM</div>
                </div>

            </div>


            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
                <!--    <div v-if="validationSuccess" class="validation-success">
                    This is a URL
                </div>
    
                <div v-if="validationFailure" class="validation-failure">
                    This is NOT a URL!
                </div> -->

                <div v-if="validationPending" class="validation-pending">
                    Long validation in progress...
                </div>

                <!--      <button class="switch" @click="switchCamera">
                    <img :src="$withBase('/camera-switch.svg')" alt="switch camera">
                </button> -->

            </qrcode-stream>

            <div class="rowButtons">

                <!--     <v-btn @click="switchCamera" class="mx-2 bottomCamera" fab large color="rgb(244, 140, 67)">
                    <v-icon color="white" dark>
                        mdi-camera
                    </v-icon>
                </v-btn> -->

            </div>

        </div>

    </div>
</template>
    
<script>
import {
    bus
} from "../main";
import $ from 'jquery';
import {
    QrcodeStream
} from 'vue-qrcode-reader'
import router from ".././router";

/* import vueInsomnia from "vue-insomnia"; */
export default {

    mounted: function () {

        $(document).ready(function () {

            //Send to flutter
            var token_system = window.$cookies.get("token_system");

            try {

                const args = [{
                    token_system: token_system
                }];
                window.flutter_inappwebview.callHandler('openReader', ...args);

            } catch (error) {
                console.log(error);
            }

        });

        /* this.$root.vueInsomnia().on(); */

        window.audioSuccess = new Audio(require('../assets/success.mp3')); // path to file
        window.audioWarning = new Audio(require('../assets/warning.mp3')); // path to file
        //  window.audioSuccess.play();

        var that = this;
        window.timing = setInterval(function () {
            console.log("ok");
            that.hour = new Date().toLocaleTimeString('it-IT');

        }, 1000);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_back":

                    this.checkPsw(0);

                    break;
                case "btn_out":

                    this.checkPsw(1);

                    break;

                case "btn_camera":

                    this.switchCamera();
                    break;

            }

        });

        this.addBackDeleteBtn();
        $(".headerTop").hide();
        $(".v-main").addClass("vmain");

    },
    computed: {
        validationPending() {
            return this.isValid === undefined &&
                this.camera === 'off'
        },

        validationSuccess() {
            return this.isValid === true
        },

        validationFailure() {
            return this.isValid === false
        }
    },

    components: {
        QrcodeStream
    },

    data() {

        var today = new Date().getDay();
        var days = ["DOM", "LUN", "MAR", "MER", "GIO", "VEN", "SAB"];
        var giorno = days[today];

        return {
            /*  camera: 'rear', */
            audioSuccess: null,
            audioWarning: null,
            lastCamera: "",
            processing: false,
            giorno: giorno,
            hour: new Date().toLocaleTimeString('it-IT'),
            noRearCamera: false,
            noFrontCamera: false,

            isValid: undefined,
            camera: 'auto',
            result: null,

        }
    },

    destroyed() {
        $(".headerTop").show();
        clearInterval(window.timing);
        bus.$off("bottomIconClickEvent");
        bus.$off("menuClickEvent");
        this.$root.$children[0].bnavbuttons = {};
    },
    methods: {


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;


        },

        checkPsw: function (logout) {
            var that = this;
            this.$swal
                .fire({
                    title: "Unblock Ebadge",
                    html: "Type correct password",
                    showCancelButton: true,
                    confirmButtonColor: "#f48c43",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Send",

                    input: "password",

                    inputAttributes: {
                        autocapitalize: "off",
                    },

                    preConfirm: (username) => {
                        that.$root.$children[0].showProgress = false;
                        var token_system = window.$cookies.get("token_system");
                        if (atob(token_system) == username) {

                            if (logout == 1) {

                                that.$root.$children[0].logout();
                                router.push({
                                    path: "/"
                                });

                            } else {
                                router.push({
                                    path: "/dash"
                                });

                            }

                        } else {
                            that.$swal.fire({
                                icon: "error",
                                title: "Wrong password",
                                showConfirmButton: true,
                                timer: 2000
                            });
                        }
                        console.log(username);
                    },
                })
        },
        addBackDeleteBtn: function () {

            var pulsantis = [];

            /*     pulsantis.push({
                    text: "",
                    icon: "mdi-home-circle",
                    link: "/dash",
                    id: "btn_exit",
                    disabled: false,
                }); */

            // pulsantis.push({
            //     text: "",
            //     icon: "mdi-arrow-left",
            //     link: "/back",
            //     id: "btn_back",
            //     disabled: false,
            // },);
            // pulsantis.push({
            //     text: "",
            //     icon: "mdi-exit-to-app",
            //     link: "/exit",
            //     id: "btn_out",
            //     disabled: false,
            // },);

            // pulsantis.push({
            //     text: "",
            //     icon: "mdi-camera",
            //     link: "/camera",
            //     id: "btn_camera",
            //     disabled: false,
            // },);

            /*    var a = window.$cookies.get("a");
               if (a == 'Y' || a == 'S') {
 
                   pulsantis.push({
                       text: "",
                       icon: "mdi-send-clock-outline",
                       link: "/btn_changestatusadmin",
                       id: "btn_changestatusadmin",
                       disabled: false,
                   }, );
 
               } */

            /*   pulsantis.push({
                  text: "",
                  icon: "mdi-delete",
                  link: "/delete",
                  id: "btn_delete",
                  disabled: false,
              }, ); */

            /*  if (a == 'Y' || a == 'S') {
                 console.log("O");
             } else {
 
                 console.log("this.currItem", this.currItem);
                 //add button send in approvation
                 if ("to Approve" != this.currItem.stato)
                     pulsantis.push({
                         text: "sendinapprovation",
                         icon: "mdi-send-clock-outline",
                         link: "/delete",
                         id: "btn_send_approvation",
                         disabled: false,
                     }, );
             } */

            this.$root.$children[0].bnavbuttons = pulsantis;

        },

        switchCamera() {
            //Test
            //if (this.camera == 'auto')this.camera = 'front';

            switch (this.camera) {
                case 'auto':
                    this.camera = 'front'
                    break

                case 'front':
                    this.camera = 'rear'
                    break
                case 'rear':
                    this.camera = 'front'
                    break
            }
        },

        async onInit(promise) {
            try {
                await promise
            } catch (error) {
                const triedFrontCamera = this.camera === 'front'
                const triedRearCamera = this.camera === 'rear'

                const cameraMissingError = error.name === 'OverconstrainedError'

                if (triedRearCamera && cameraMissingError) {
                    this.noRearCamera = true
                }

                if (triedFrontCamera && cameraMissingError) {
                    this.noFrontCamera = true
                }

                console.error(error)
            }
        },

        resetValidationState() {
            this.isValid = undefined
        },


        async onDecode(content) {

            var that = this;

            console.log("Entering");
            this.processing = true;

            this.result = content

            console.log("CONTENT: ", content);

            console.log("CONTENT DECODE: ", atob(content));



            this.isValid = true

            // // some more delay, so users have time to read the message

            setTimeout(async () => {
                that.pause(that)
                await that.timeout(2000)
                that.unpause(that)
            }, 100);

            this.result = '';


            var API_URL = that.getUrlDomain("qrcodeSaveRegistration");

            var myMsgError = "";

            var data = {
                'token': content
            }
            console.log("DATA_", data);
            $.ajax({
                type: "POST",
                url: API_URL,
                timeout: 15000,
                data: data,
                success: function (resultData) {
                    console.log("resultData :", resultData);

                    setTimeout(() => {
                        that.processing = false;
                    }, 300);


                    if (resultData.Result == "OK") {
                        that.$swal({
                            icon: "success",
                            text: resultData.Messaggio,
                            showConfirmButton: false,
                            timer: 4000
                        });
                    }

                    if (resultData.Result == "ERROR") {

                        that.$swal({
                            icon: "error",
                            text: resultData.Messaggio,
                            showConfirmButton: false,
                            timer: 4000
                        });
                    }


                },
                error: function (request, status, err) {

                    setTimeout(() => {
                        that.processing = false;
                    }, 300);

                    if (status == "timeout") {

                        myMsgError = "Verificare la connessione e riprovare";
                        that.$swal({
                            icon: "error",
                            text: myMsgError,
                            showConfirmButton: false,
                            timer: 4000
                        });

                    } else {

                        // another error occured
                        console.log("status", status);
                        myMsgError = request + status + err;
                        myMsgError = "Errore generico contattare amministatore";

                        that.$swal({
                            icon: "error",
                            text: myMsgError,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }
                }

            });

        },





        unpause: function (that) {
            that.camera = that.lastCamera
        },

        pause: function (that) {
            that.lastCamera = that.camera;
            that.camera = 'off';

        },

        turnCameraOn() {
            this.camera = 'auto'
            this.result = ''
        },

        turnCameraOff() {
            this.camera = 'off'
        },

        timeout(ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        }

    }
}
</script>
    
<style>
.centerSquare {
    position: fixed;
    z-index: 3;

    position: fixed;
    left: calc((50vw - 50%) * -1);
    top: calc((50vh - 50%) * -1);
    transform: translate(calc(50vw - 50%), calc(50vh - 50%));

    box-shadow: 0 0 0 9999px rgb(55 55 60 / 54%);
    border-radius: 12px;

}

.overlaybox {
    min-height: 250px;
    min-width: 250px;

    position: relative;
    width: 100%;
    height: 100%;

    --border-style: 9px solid#1c6881;
    --border-space: -9px;
}

/*#   f48c43*/

.overlayboxpending {

    --border-style: 9px solid #f48c43;
    animation: blinkMe 2s linear infinite;
}

.overlay-element {

    position: absolute;
    width: 50px;
    height: 50px;
}

.overlaybox .top-left {
    border-left: var(--border-style);
    border-top: var(--border-style);
    top: var(--border-space);
    left: var(--border-space);
    border-radius: 20px 0px 0px 0px;

}

.overlaybox .top-right {
    border-right: var(--border-style);
    border-top: var(--border-style);
    top: var(--border-space);
    right: var(--border-space);
    border-radius: 0px 20px 0px 0px;
}

.overlaybox .bottom-left {
    border-left: var(--border-style);
    border-bottom: var(--border-style);
    bottom: var(--border-space);
    border-radius: 0px 0px 0px 20px;
    left: var(--border-space);
}

.overlaybox .bottom-right {
    border-radius: 0px 0px 20px 0px;
    border-right: var(--border-style);
    border-bottom: var(--border-style);
    bottom: var(--border-space);
    right: var(--border-space);
}

.qrcode-stream-overlay {}

.mainwrapper {}

.hour {}

.rowWeek {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    min-width: 290px;

    justify-content: space-around;

    color: grey;
    font-weight: 700;

}

.rowButtons {
    position: absolute;
    z-index: 4;
    bottom: 22vh;
    display: flex;
    min-width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
}

.bottomCamera {
    color: red;
}

.rowhour {
    position: absolute;
    z-index: 3;
    top: 6vh;
    display: flex;
    min-width: 100%;
    justify-content: center;
    font-size: 64px;
    color: #f48c43;
    flex-direction: column;
    align-items: center;
}

.vmain {
    padding: 0px !important;

}

.badgelogo {
    position: absolute;
    top: 10px;
    z-index: 3;
}

.error {
    color: red;
    font-weight: bold;
}

.v-application .error {

    color: black;
    background-color: white !important;
}

.qrcode-stream-wrapper {

    min-height: 100vh;
    min-width: 100vw;
}

.qrcode-stream-camera {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover !important;
    min-height: 100vh;
    min-width: 100vw;
    position: absolute;
    top: 0px;
}

.switch {
    position: absolute;
    right: 22px;
    top: 10px;
}

.decode-result {
    position: absolute;
    z-index: 1;
    display: none;
}

.rowWeek .active {
    color: #f48c43;

}

@keyframes blinkMe {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
    