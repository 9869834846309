import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import invioPresenze from './components/invioPresenze.vue'
import timesheetGrid from './components/timesheetGrid.vue'
import pianoOrario from './components/pianoOrario.vue'
import editPianoOrario from './components/editPianoOrario.vue'
import copyPianoOrario from './components/copyPianoOrario.vue'
import risorse from './components/risorse.vue'
import editRisorsa from './components/editRisorsa.vue'
import filterRisorse from './components/filterRisorse.vue'
import company from './components/company.vue'
import editCompany from './components/editCompany.vue'
import filterPianoOrario from './components/filterPianoOrario.vue'
import companyBasic from './components/companyBasic.vue'
import editCompanyBasic from './components/editCompanyBasic.vue'
import assocUserCompany from './components/assocUserCompany.vue'
import assocUserCompanyListCmp from './components/assocUserCompanyListCmp.vue'
import holidayHistory from './components/holidayHistory.vue'
import filterHistoryDisease from './components/filterHistoryDisease.vue'
import filterHolidayHistory from './components/filterHolidayHistory.vue'
import verificaTimesheet from './components/verificaTimesheet.vue'
import editVerificaTimesheet from './components/editVerificaTimesheet.vue'
import filterVerificaTimesheet from './components/filterVerificaTimesheet.vue'
import filterVerificaTimesheetClientiFornitori from './components/filterVerificaTimesheetClientiFornitori.vue'
import verificaTimesheetClientiFornitori from './components/verificaTimesheetClientiFornitori.vue'
import invitoClientiFornitori from './components/invitoClientiFornitori.vue'
import vediTimesheetClientiFornitori from './components/vediTimesheetClientiFornitori.vue'
import reservateCustomerSupplier from './components/reservateCustomerSupplier.vue'
import changepasswordinitialcustsup from './components/changepasswordinitialcustsup.vue'
import reservate from './components/reservate.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import cespitiAdmin from './components/cespitiAdmin.vue'
import filterCespitiAdmin from './components/filterCespitiAdmin.vue'
import editCespitiAdmin from './components/editCespitiAdmin.vue'
import cespiti from './components/cespiti.vue'
import initCespiti from './components/initCespiti.vue'
import reservateCespiti from './components/reservateCespiti.vue'
import editCespiti from './components/editCespiti.vue'
import filterPerformanceTimesheet from './components/filterPerformanceTimesheet.vue'
import performanceTimesheet from './components/performanceTimesheet.vue'
import performanceTimesheetSettings from './components/performanceTimesheetSettings.vue'
import referenti from './components/referenti.vue'
import filterReferenti from './components/filterReferenti.vue'
import editReferente from './components/editReferente.vue'
import copyReferente from './components/copyReferente.vue'
import configurationProducts from './components/configurationProducts.vue'
import editConfigurationProduct from './components/editConfigurationProduct.vue'
import homeSU from './components/homeSU.vue'
import reservateHome from './components/reservateHome.vue'
import tipoProgetto from './components/tipoProgetto.vue'
import editTipoProgetto from './components/editTipoProgetto.vue'
import settoriAttivita from './components/settoriAttivita.vue'
import editSettoreAttivita from './components/editSettoreAttivita.vue'
import commercialisti from './components/commercialisti.vue'
import editCommecrialista from './components/editCommecrialista.vue'
import statoPratiche from './components/statoPratiche.vue'
import editStatoPratica from './components/editStatoPratica.vue'
import statiPagamento from './components/statiPagamento.vue'
import editStatoPagamento from './components/editStatoPagamento.vue'
import descrModalitaFatturazione from './components/descrModalitaFatturazione.vue'
import editDescrModalitaFatturazione from './components/editDescrModalitaFatturazione.vue'
import tipoControllo from './components/tipoControllo.vue'
import editTipoControllo from './components/editTipoControllo.vue'
import sediOperative from './components/sediOperative.vue'
import editSedeOperativa from './components/editSedeOperativa.vue'
import controlli from './components/controlli.vue'
import editControllo from './components/editControllo.vue'
import controlliCondizioni from './components/controlliCondizioni.vue'
import controlliStandard from './components/controlliStandard.vue'
import editControlloStandard from './components/editControlloStandard.vue'
import controlliStandardCondizioni from './components/controlliStandardCondizioni.vue'
import scadenze from './components/scadenze.vue'
import editScadenza from './components/editScadenza.vue'
import scadenzeStandard from './components/scadenzeStandard.vue'
import editScadenzaStandard from './components/editScadenzaStandard.vue'
import pratiche from './components/pratiche.vue'
import editPratica from './components/editPratica.vue'
import historyControlli from './components/historyControlli.vue'
import modalitaFatturazione from './components/modalitaFatturazione.vue'
import editModalitaFatturazione from './components/editModalitaFatturazione.vue'
import editModFattTest from './components/editModFattTest.vue'
import companyAgila from './components/companyAgila.vue'
import editCompanyAgila from './components/editCompanyAgila.vue'
import importResult from './components/importResult.vue'
import reloadPage from './components/reloadPage.vue'
import createResourceFromUser from './components/createResourceFromUser.vue'
import logoFirma from './components/logoFirma.vue'
import initHoliday from './components/initHoliday.vue'
import initDisease from './components/initDisease.vue'
import usersQRCodeGenerated from './components/usersQRCodeGenerated.vue'
import filterVerificaTimesheetEbadge from './components/filterVerificaTimesheetEbadge.vue'
import verificaTimesheetEbadge from './components/verificaTimesheetEbadge.vue'
import editVerificaTimesheetEbadge from './components/editVerificaTimesheetEbadge.vue'
import changepasswordprofile from './components/changepasswordprofile.vue'
import reminderTimesheet from './components/reminderTimesheet.vue'
import filterReminderTimesheet from './components/filterReminderTimesheet.vue'
import editReminderTimesheet from './components/editReminderTimesheet.vue'
import reminderTimesheetUsersDest from './components/reminderTimesheetUsersDest.vue'
import filterReminderHolidays from './components/filterReminderHolidays.vue'
import reminderHolidays from './components/reminderHolidays.vue'
import editReminderHolidays from './components/editReminderHolidays.vue'
import vediPratica from './components/vediPratica.vue'
import vediCompanyAgila from './components/vediCompanyAgila.vue'
import initPratiche from './components/initPratiche.vue'
import initCompanyAgila from './components/initCompanyAgila.vue'
import tipiCespiti from './components/tipiCespiti.vue'
import editTipoCespiti from './components/editTipoCespiti.vue'
import copyCespitiAdmin from './components/copyCespitiAdmin.vue'
import reservateHolidaysPermitsFromMail from './components/reservateHolidaysPermitsFromMail.vue'
import initHolidayHistory from './components/initHolidayHistory.vue'
import risorseClientiProgetti from './components/risorseClientiProgetti.vue'
import editRisorseClientiProgetti from './components/editRisorseClientiProgetti.vue'
import reloadEditVerificaTimesheet from './components/reloadEditVerificaTimesheet.vue'
import timesheetGridWithRep from './components/timesheetGridWithRep.vue'
import editVerificaTimesheetWithRep from './components/editVerificaTimesheetWithRep.vue'
import reloadEditVerificaTimesheetWithRep from './components/reloadEditVerificaTimesheetWithRep.vue'
import verificaTimesheetCliente from './components/verificaTimesheetCliente.vue'
import editVerificaTimesheetCliente from './components/editVerificaTimesheetCliente.vue'
import editVerificaTimesheetClienteWithRep from './components/editVerificaTimesheetClienteWithRep.vue'
import reloadEditVerificaTimesheetCliente from './components/reloadEditVerificaTimesheetCliente.vue'
import reloadEditVerificaTimesheetClienteWithRep from './components/reloadEditVerificaTimesheetClienteWithRep.vue'
import verificaTimesheetFornitori from './components/verificaTimesheetFornitori.vue'
import editVerificaTimesheetFornitore from './components/editVerificaTimesheetFornitore.vue'
import editVerificaTimesheetFornitoreWithRep from './components/editVerificaTimesheetFornitoreWithRep.vue'
import reloadEditVerificaTimesheetFornitore from './components/reloadEditVerificaTimesheetFornitore.vue'
import reloadEditVerificaTimesheetFornitoreWithRep from './components/reloadEditVerificaTimesheetFornitoreWithRep.vue'
import reservateCustomer from './components/reservateCustomer.vue'
import filterVerificaTimesheetCliente from './components/filterVerificaTimesheetCliente.vue'
import initVerificaTimesheetCliente from './components/initVerificaTimesheetCliente.vue'
import filterVerificaTimesheetFornitori from './components/filterVerificaTimesheetFornitori.vue'
import initVerificaTimesheetFornitori from './components/initVerificaTimesheetFornitori.vue'
import diseaseHistory from './components/diseaseHistory.vue'
import editDisease from './components/editDisease.vue'
import filterGroups from './components/filterGroups.vue'
import groups from './components/groups.vue'
import editGroup from './components/editGroup.vue'
import editGroupSelUsers from './components/editGroupSelUsers.vue'
import editGroupMails from './components/editGroupMails.vue'
import editGroupEditMail from './components/editGroupEditMail.vue'
import editGroupMailsRefresh from './components/editGroupMailsRefresh.vue'
import editVerificaTimesheetClienteUnProj from './components/editVerificaTimesheetClienteUnProj.vue'
import timesheetGridUnProj from './components/timesheetGridUnProj.vue'
import timesheetGridUnProjWithRep from './components/timesheetGridUnProjWithRep.vue'
import editVerificaTimesheetUnProj from './components/editVerificaTimesheetUnProj.vue'
import editVerificaTimesheetUnProjWithRep from './components/editVerificaTimesheetUnProjWithRep.vue'
import editVerificaTimesheetClienteUnProjWithRep from './components/editVerificaTimesheetClienteUnProjWithRep.vue'
import logMail from './components/logMail.vue'
import reloadEditVerificaTimesheetUnProj from './components/reloadEditVerificaTimesheetUnProj.vue'
import reloadEditVerificaTimesheetUnProjWithRep from './components/reloadEditVerificaTimesheetUnProjWithRep.vue'
import reloadEditVerificaTimesheetClienteUnProj from './components/reloadEditVerificaTimesheetClienteUnProj.vue'
import reservateRecoverPassword from './components/reservateRecoverPassword.vue'
import recoverpasswordprofile from './components/recoverpasswordprofile.vue'
import confMailServer from './components/confMailServer.vue'
import editConfMailServer from './components/editConfMailServer.vue'
import copyConfMailServer from './components/copyConfMailServer.vue'
import confDaysPwd from './components/confDaysPwd.vue'
import timesheetGridUnProjWithRepInterv from './components/timesheetGridUnProjWithRepInterv.vue'
import timesheetGridWithRepInterv from './components/timesheetGridWithRepInterv.vue'
import editVerificaTimesheetUnProjWithRepInterv from './components/editVerificaTimesheetUnProjWithRepInterv.vue'
import editVerificaTimesheetWithRepInterv from './components/editVerificaTimesheetWithRepInterv.vue'
import confDaysLogout from './components/confDaysLogout.vue'
import confAutoApprHolidays from './components/confAutoApprHolidays.vue'
import festivity from './components/festivity.vue'
import filterFestivity from './components/filterFestivity.vue'
import editFestivity from './components/editFestivity.vue'
import reception from './components/reception.vue'
import manualRegistration from './components/manualRegistration.vue'
import qrcodeRegistration from './components/qrcodeRegistration.vue'
import createQRCodeFrequentlyUsers from './components/createQRCodeFrequentlyUsers.vue'
import qrcodeRegistrationTest from './components/qrcodeRegistrationTest.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },

        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props: true
        },

        {
            path: '/settings',
            name: 'settings',
            component: settings
        },

        {
            path: '/invioPresenze',
            name: 'invioPresenze',
            component: invioPresenze
        },


        {
            path: '/timesheetGrid',
            name: 'timesheetGrid',
            component: timesheetGrid
        },

        {
            path: '/pianoOrario',
            name: 'pianoOrario',
            component: pianoOrario
        }

        ,
        {
            path: '/editPianoOrario/:id',
            name: 'editPianoOrario',
            component: editPianoOrario,
            props: true
        }

        ,
        {
            path: '/copyPianoOrario/:id',
            name: 'copyPianoOrario',
            component: copyPianoOrario,
            props: true
        },


        {
            path: '/risorse',
            name: 'risorse',
            component: risorse
        }

        ,
        {
            path: '/editRisorsa/:id',
            name: 'editRisorsa',
            component: editRisorsa,
            props: true
        },


        {
            path: '/filterRisorse',
            name: 'filterRisorse',
            component: filterRisorse,
            props: true
        },


        {
            path: '/company',
            name: 'company',
            component: company,
            props: true
        },


        {
            path: '/editCompany/:id',
            name: 'editCompany',
            component: editCompany,
            props: true
        },


        {
            path: '/filterPianoOrario',
            name: 'filterPianoOrario',
            component: filterPianoOrario,
            props: true
        },


        {
            path: '/companyBasic',
            name: 'companyBasic',
            component: companyBasic,
            props: true
        },


        {
            path: '/editCompanyBasic/:id',
            name: 'editCompanyBasic',
            component: editCompanyBasic,
            props: true
        },

        {
            path: '/assocUserCompany',
            name: 'assocUserCompany',
            component: assocUserCompany,
            props: true
        },

        {
            path: '/assocUserCompanyListCmp',
            name: 'assocUserCompanyListCmp',
            component: assocUserCompanyListCmp,
            props: true
        },

        {
            path: '/holidayHistory',
            name: 'holidayHistory',
            component: holidayHistory,
            props: true
        },

        {
            path: '/diseaseHistory',
            name: 'diseaseHistory',
            component: diseaseHistory,
            props: true
        },

        {
            path: '/editDisease/:id',
            name: 'editDisease',
            component: editDisease,
            props: true
        },


        {
            path: '/filterGroups',
            name: 'filterGroups',
            component: filterGroups,
            props: true
        },

        {
            path: '/groups',
            name: 'groups',
            component: groups,
            props: true
        },


        {
            path: '/editGroup/:id',
            name: 'editGroup',
            component: editGroup,
            props: true
        },

        {
            path: '/editGroupSelUsers',
            name: 'editGroupSelUsers',
            component: editGroupSelUsers,
            props: true
        },

        {
            path: '/editGroupMails',
            name: 'editGroupMails',
            component: editGroupMails,
            props: true
        },

        {
            path: '/editGroupEditMail/:id',
            name: 'editGroupEditMail',
            component: editGroupEditMail,
            props: true
        },

        {
            path: '/editGroupMailsRefresh',
            name: 'editGroupMailsRefresh',
            component: editGroupMailsRefresh,
            props: true
        },

        {
            path: '/filterHistoryDisease',
            name: 'filterHistoryDisease',
            component: filterHistoryDisease,
            props: true
        },

        {
            path: '/filterHolidayHistory',
            name: 'filterHolidayHistory',
            component: filterHolidayHistory,
            props: true
        },


        {
            path: '/verificaTimesheet',
            name: 'verificaTimesheet',
            component: verificaTimesheet,
            props: true
        },

        {
            path: '/editVerificaTimesheet',
            name: 'editVerificaTimesheet',
            component: editVerificaTimesheet,
            props: true
        },


        {
            path: '/filterVerificaTimesheet',
            name: 'filterVerificaTimesheet',
            component: filterVerificaTimesheet,
            props: true
        },

        {
            path: '/filterVerificaTimesheetClientiFornitori',
            name: 'filterVerificaTimesheetClientiFornitori',
            component: filterVerificaTimesheetClientiFornitori,
            props: true
        },

        {
            path: '/verificaTimesheetClientiFornitori',
            name: 'verificaTimesheetClientiFornitori',
            component: verificaTimesheetClientiFornitori,
            props: true
        },

        {
            path: '/invitoClientiFornitori',
            name: 'invitoClientiFornitori',
            component: invitoClientiFornitori,
            props: true
        },

        {
            path: '/vediTimesheetClientiFornitori',
            name: 'vediTimesheetClientiFornitori',
            component: vediTimesheetClientiFornitori,
            props: true
        },

        {
            path: '/reservateCustomerSupplier/:id',
            name: 'reservateCustomerSupplier',
            component: reservateCustomerSupplier,
            props: true
        },

        {
            path: '/changepasswordinitialcustsup',
            name: 'changepasswordinitialcustsup',
            component: changepasswordinitialcustsup,
            props: true
        },

        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        },


        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial,
            props: true
        },

        {
            path: '/cespitiAdmin',
            name: 'cespitiAdmin',
            component: cespitiAdmin,
            props: true
        },

        {
            path: '/filterCespitiAdmin',
            name: 'filterCespitiAdmin',
            component: filterCespitiAdmin,
            props: true
        },

        {
            path: '/editCespitiAdmin/:id',
            name: 'editCespitiAdmin',
            component: editCespitiAdmin,
            props: true
        },

        {
            path: '/cespiti',
            name: 'cespiti',
            component: cespiti,
            props: true
        },


        {
            path: '/initCespiti',
            name: 'initCespiti',
            component: initCespiti,
            props: true
        },

        {
            path: '/reservateCespiti/:id',
            name: 'reservateCespiti',
            component: reservateCespiti,
            props: true
        },


        {
            path: '/editCespiti/:id',
            name: 'editCespiti',
            component: editCespiti,
            props: true
        },

        {
            path: '/filterPerformanceTimesheet',
            name: 'filterPerformanceTimesheet',
            component: filterPerformanceTimesheet,
            props: true
        },



        {
            path: '/performanceTimesheet',
            name: 'performanceTimesheet',
            component: performanceTimesheet,
            props: true
        },


        {
            path: '/performanceTimesheetSettings',
            name: 'performanceTimesheetSettings',
            component: performanceTimesheetSettings,
            props: true
        },

        {
            path: '/referenti',
            name: 'referenti',
            component: referenti,
            props: true
        },

        {
            path: '/filterReferenti',
            name: 'filterReferenti',
            component: filterReferenti,
            props: true
        },


        {
            path: '/editReferente/:id',
            name: 'editReferente',
            component: editReferente,
            props: true
        },

        {
            path: '/copyReferente/:id',
            name: 'copyReferente',
            component: copyReferente,
            props: true
        },


        {
            path: '/configurationProducts',
            name: 'configurationProducts',
            component: configurationProducts,
            props: true
        },

        {
            path: '/editConfigurationProduct/:id',
            name: 'editConfigurationProduct',
            component: editConfigurationProduct,
            props: true
        },

        {
            path: '/homeSU',
            name: 'homeSU',
            component: homeSU,
            props: true
        },


        {
            path: '/reservateHome/:id',
            name: 'reservateHome',
            component: reservateHome,
            props: true
        },

        {
            path: '/tipoProgetto',
            name: 'tipoProgetto',
            component: tipoProgetto,
            props: true
        },

        {
            path: '/editTipoProgetto/:id',
            name: 'editTipoProgetto',
            component: editTipoProgetto,
            props: true
        },

        {
            path: '/settoriAttivita',
            name: 'settoriAttivita',
            component: settoriAttivita,
            props: true
        },

        {
            path: '/editSettoreAttivita/:id',
            name: 'editSettoreAttivita',
            component: editSettoreAttivita,
            props: true
        },

        {
            path: '/commercialisti',
            name: 'commercialisti',
            component: commercialisti,
            props: true
        },


        {
            path: '/editCommecrialista/:id',
            name: 'editCommecrialista',
            component: editCommecrialista,
            props: true
        },

        {
            path: '/statoPratiche',
            name: 'statoPratiche',
            component: statoPratiche,
            props: true
        },

        {
            path: '/editStatoPratica/:id',
            name: 'editStatoPratica',
            component: editStatoPratica,
            props: true
        },

        {
            path: '/statiPagamento',
            name: 'statiPagamento',
            component: statiPagamento,
            props: true
        },

        {
            path: '/editStatoPagamento/:id',
            name: 'editStatoPagamento',
            component: editStatoPagamento,
            props: true
        },

        {
            path: '/descrModalitaFatturazione',
            name: 'descrModalitaFatturazione',
            component: descrModalitaFatturazione,
            props: true
        },

        {
            path: '/editDescrModalitaFatturazione/:id',
            name: 'editDescrModalitaFatturazione',
            component: editDescrModalitaFatturazione,
            props: true
        },

        {
            path: '/tipoControllo',
            name: 'tipoControllo',
            component: tipoControllo,
            props: true
        },

        {
            path: '/editTipoControllo/:id',
            name: 'editTipoControllo',
            component: editTipoControllo,
            props: true
        },

        {
            path: '/sediOperative',
            name: 'sediOperative',
            component: sediOperative,
            props: true
        },

        {
            path: '/editSedeOperativa/:id',
            name: 'editSedeOperativa',
            component: editSedeOperativa,
            props: true
        },


        {
            path: '/controlli/:id/:id2/:id3/:id4',
            name: 'controlli',
            component: controlli,
            props: true
        },

        {
            path: '/editControllo/:id',
            name: 'editControllo',
            component: editControllo,
            props: true
        },


        {
            path: '/controlliCondizioni/:id',
            name: 'controlliCondizioni',
            component: controlliCondizioni,
            props: true
        },

        {
            path: '/controlliStandard',
            name: 'controlliStandard',
            component: controlliStandard,
            props: true
        },

        {
            path: '/editControlloStandard/:id',
            name: 'editControlloStandard',
            component: editControlloStandard,
            props: true
        },

        {
            path: '/controlliStandardCondizioni/:id',
            name: 'controlliStandardCondizioni',
            component: controlliStandardCondizioni,
            props: true
        },

        {
            path: '/scadenze/:id',
            name: 'scadenze',
            component: scadenze,
            props: true
        },


        {
            path: '/editScadenza/:id',
            name: 'editScadenza',
            component: editScadenza,
            props: true
        },


        {
            path: '/scadenzeStandard',
            name: 'scadenzeStandard',
            component: scadenzeStandard,
            props: true
        },

        {
            path: '/editScadenzaStandard/:id',
            name: 'editScadenzaStandard',
            component: editScadenzaStandard,
            props: true
        },


        {
            path: '/pratiche',
            name: 'pratiche',
            component: pratiche,
            props: true
        },


        {
            path: '/editPratica/:id',
            name: 'editPratica',
            component: editPratica,
            props: true
        },



        {
            path: '/historyControlli/:id',
            name: 'historyControlli',
            component: historyControlli,
            props: true
        },

        {
            path: '/modalitaFatturazione/:id',
            name: 'modalitaFatturazione',
            component: modalitaFatturazione,
            props: true
        },

        {
            path: '/editModalitaFatturazione/:id',
            name: 'editModalitaFatturazione',
            component: editModalitaFatturazione,
            props: true
        },

        {
            path: '/editModFattTest/:id',
            name: 'editModFattTest',
            component: editModFattTest,
            props: true
        },


        {
            path: '/companyAgila',
            name: 'companyAgila',
            component: companyAgila,
            props: true
        },

        {
            path: '/editCompanyAgila/:id',
            name: 'editCompanyAgila',
            component: editCompanyAgila,
            props: true
        },

        {
            path: '/importResult',
            name: 'importResult',
            component: importResult,
            props: true
        },

        {
            path: '/reloadPage',
            name: 'reloadPage',
            component: reloadPage,
            props: true
        },


        {
            path: '/createResourceFromUser',
            name: 'createResourceFromUser',
            component: createResourceFromUser,
            props: true
        },

        {
            path: '/logoFirma',
            name: 'logoFirma',
            component: logoFirma,
            props: true
        },

        {
            path: '/initHoliday',
            name: 'initHoliday',
            component: initHoliday,
            props: true
        },

        {
            path: '/initDisease',
            name: 'initDisease',
            component: initDisease,
            props: true
        },


        {
            path: '/usersQRCodeGenerated',
            name: 'usersQRCodeGenerated',
            component: usersQRCodeGenerated,
            props: true
        },


        {
            path: '/filterVerificaTimesheetEbadge',
            name: 'filterVerificaTimesheetEbadge',
            component: filterVerificaTimesheetEbadge,
            props: true
        },

        {
            path: '/verificaTimesheetEbadge',
            name: 'verificaTimesheetEbadge',
            component: verificaTimesheetEbadge,
            props: true
        },


        {
            path: '/editVerificaTimesheetEbadge',
            name: 'editVerificaTimesheetEbadge',
            component: editVerificaTimesheetEbadge,
            props: true
        },

        {
            path: '/changepasswordprofile',
            name: 'changepasswordprofile',
            component: changepasswordprofile
        },

        {
            path: '/reminderTimesheet',
            name: 'reminderTimesheet',
            component: reminderTimesheet
        },

        {
            path: '/filterReminderTimesheet',
            name: 'filterReminderTimesheet',
            component: filterReminderTimesheet
        },

        {
            path: '/editReminderTimesheet/:id',
            name: 'editReminderTimesheet',
            component: editReminderTimesheet,
            props: true
        },

        {
            path: '/reminderTimesheetUsersDest',
            name: 'reminderTimesheetUsersDest',
            component: reminderTimesheetUsersDest
        },

        {
            path: '/filterReminderHolidays',
            name: 'filterReminderHolidays',
            component: filterReminderHolidays
        },

        {
            path: '/reminderHolidays',
            name: 'reminderHolidays',
            component: reminderHolidays
        },

        {
            path: '/editReminderHolidays/:id',
            name: 'editReminderHolidays',
            component: editReminderHolidays,
            props: true
        },

        {
            path: '/vediPratica/:id',
            name: 'vediPratica',
            component: vediPratica,
            props: true
        },

        {
            path: '/vediCompanyAgila/:id',
            name: 'vediCompanyAgila',
            component: vediCompanyAgila,
            props: true
        },

        {
            path: '/initPratiche',
            name: 'initPratiche',
            component: initPratiche
        },


        {
            path: '/initCompanyAgila',
            name: 'initCompanyAgila',
            component: initCompanyAgila
        },

        {
            path: '/tipiCespiti',
            name: 'tipiCespiti',
            component: tipiCespiti
        },

        {
            path: '/editTipoCespiti/:id',
            name: 'editTipoCespiti',
            component: editTipoCespiti
        },

        {
            path: '/copyCespitiAdmin/:id',
            name: 'copyCespitiAdmin',
            component: copyCespitiAdmin
        },

        {
            path: '/reservateHolidaysPermitsFromMail/:id',
            name: 'reservateHolidaysPermitsFromMail',
            component: reservateHolidaysPermitsFromMail,
            props: true
        },

        {
            path: '/initHolidayHistory',
            name: 'initHolidayHistory',
            component: initHolidayHistory
        },

        {
            path: '/risorseClientiProgetti',
            name: 'risorseClientiProgetti',
            component: risorseClientiProgetti
        },

        {
            path: '/editRisorseClientiProgetti/:id',
            name: 'editRisorseClientiProgetti',
            component: editRisorseClientiProgetti,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheet',
            name: 'reloadEditVerificaTimesheet',
            component: reloadEditVerificaTimesheet
        },

        {
            path: '/timesheetGridWithRep',
            name: 'timesheetGridWithRep',
            component: timesheetGridWithRep
        },

        {
            path: '/editVerificaTimesheetWithRep',
            name: 'editVerificaTimesheetWithRep',
            component: editVerificaTimesheetWithRep
        },

        {
            path: '/reloadEditVerificaTimesheetWithRep',
            name: 'reloadEditVerificaTimesheetWithRep',
            component: reloadEditVerificaTimesheetWithRep
        },

        {
            path: '/verificaTimesheetCliente',
            name: 'verificaTimesheetCliente',
            component: verificaTimesheetCliente
        },


        {
            path: '/editVerificaTimesheetCliente',
            name: 'editVerificaTimesheetCliente',
            component: editVerificaTimesheetCliente,
            props: true
        },

        {
            path: '/editVerificaTimesheetClienteWithRep',
            name: 'editVerificaTimesheetClienteWithRep',
            component: editVerificaTimesheetClienteWithRep,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheetCliente',
            name: 'reloadEditVerificaTimesheetCliente',
            component: reloadEditVerificaTimesheetCliente,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheetClienteWithRep',
            name: 'reloadEditVerificaTimesheetClienteWithRep',
            component: reloadEditVerificaTimesheetClienteWithRep,
            props: true
        },

        {
            path: '/verificaTimesheetFornitori',
            name: 'verificaTimesheetFornitori',
            component: verificaTimesheetFornitori,
            props: true
        },

        {
            path: '/editVerificaTimesheetFornitore',
            name: 'editVerificaTimesheetFornitore',
            component: editVerificaTimesheetFornitore,
            props: true
        },

        {
            path: '/editVerificaTimesheetFornitoreWithRep',
            name: 'editVerificaTimesheetFornitoreWithRep',
            component: editVerificaTimesheetFornitoreWithRep,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheetFornitore',
            name: 'reloadEditVerificaTimesheetFornitore',
            component: reloadEditVerificaTimesheetFornitore,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheetFornitoreWithRep',
            name: 'reloadEditVerificaTimesheetFornitoreWithRep',
            component: reloadEditVerificaTimesheetFornitoreWithRep,
            props: true
        },


        {
            path: '/reservateCustomer/:id',
            name: 'reservateCustomer',
            component: reservateCustomer,
            props: true
        },

        {
            path: '/filterVerificaTimesheetCliente',
            name: 'filterVerificaTimesheetCliente',
            component: filterVerificaTimesheetCliente,
            props: true
        },

        {
            path: '/initVerificaTimesheetCliente',
            name: 'initVerificaTimesheetCliente',
            component: initVerificaTimesheetCliente,
            props: true
        },

        {
            path: '/filterVerificaTimesheetFornitori',
            name: 'filterVerificaTimesheetFornitori',
            component: filterVerificaTimesheetFornitori,
            props: true
        },


        {
            path: '/initVerificaTimesheetFornitori',
            name: 'initVerificaTimesheetFornitori',
            component: initVerificaTimesheetFornitori,
            props: true
        },

        {
            path: '/editVerificaTimesheetClienteUnProj',
            name: 'editVerificaTimesheetClienteUnProj',
            component: editVerificaTimesheetClienteUnProj,
            props: true
        },

        {
            path: '/timesheetGridUnProj',
            name: 'timesheetGridUnProj',
            component: timesheetGridUnProj,
            props: true
        },

        {
            path: '/timesheetGridUnProjWithRep',
            name: 'timesheetGridUnProjWithRep',
            component: timesheetGridUnProjWithRep,
            props: true
        },

        {
            path: '/editVerificaTimesheetUnProj',
            name: 'editVerificaTimesheetUnProj',
            component: editVerificaTimesheetUnProj,
            props: true
        },

        {
            path: '/editVerificaTimesheetUnProjWithRep',
            name: 'editVerificaTimesheetUnProjWithRep',
            component: editVerificaTimesheetUnProjWithRep,
            props: true
        },

        {
            path: '/editVerificaTimesheetClienteUnProjWithRep',
            name: 'editVerificaTimesheetClienteUnProjWithRep',
            component: editVerificaTimesheetClienteUnProjWithRep,
            props: true
        },

        {
            path: '/logMail',
            name: 'logMail',
            component: logMail,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheetUnProj',
            name: 'reloadEditVerificaTimesheetUnProj',
            component: reloadEditVerificaTimesheetUnProj,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheetUnProjWithRep',
            name: 'reloadEditVerificaTimesheetUnProjWithRep',
            component: reloadEditVerificaTimesheetUnProjWithRep,
            props: true
        },

        {
            path: '/reloadEditVerificaTimesheetClienteUnProj',
            name: 'reloadEditVerificaTimesheetClienteUnProj',
            component: reloadEditVerificaTimesheetClienteUnProj,
            props: true
        },

        {
            path: '/reservateRecoverPassword/:id',
            name: 'reservateRecoverPassword',
            component: reservateRecoverPassword,
            props: true
        },

        {
            path: '/recoverpasswordprofile',
            name: 'recoverpasswordprofile',
            component: recoverpasswordprofile,
            props: true
        },

        {
            path: '/confMailServer',
            name: 'confMailServer',
            component: confMailServer,
            props: true
        },

        {
            path: '/editConfMailServer/:id',
            name: 'editConfMailServer',
            component: editConfMailServer,
            props: true
        },

        {
            path: '/copyConfMailServer/:id',
            name: 'copyConfMailServer',
            component: copyConfMailServer,
            props: true
        },

        {
            path: '/confDaysPwd',
            name: 'confDaysPwd',
            component: confDaysPwd,
            props: true
        },

        {
            path: '/timesheetGridUnProjWithRepInterv',
            name: 'timesheetGridUnProjWithRepInterv',
            component: timesheetGridUnProjWithRepInterv,
            props: true
        },

        {
            path: '/timesheetGridWithRepInterv',
            name: 'timesheetGridWithRepInterv',
            component: timesheetGridWithRepInterv,
            props: true
        },

        {
            path: '/editVerificaTimesheetUnProjWithRepInterv',
            name: 'editVerificaTimesheetUnProjWithRepInterv',
            component: editVerificaTimesheetUnProjWithRepInterv,
            props: true
        },

        {
            path: '/editVerificaTimesheetWithRepInterv',
            name: 'editVerificaTimesheetWithRepInterv',
            component: editVerificaTimesheetWithRepInterv,
            props: true
        },

        {
            path: '/confDaysLogout',
            name: 'confDaysLogout',
            component: confDaysLogout,
            props: true
        },

        {
            path: '/confAutoApprHolidays',
            name: 'confAutoApprHolidays',
            component: confAutoApprHolidays,
            props: true
        },

        {
            path: '/festivity',
            name: 'festivity',
            component: festivity,
            props: true
        },

        {
            path: '/filterFestivity',
            name: 'filterFestivity',
            component: filterFestivity,
            props: true
        },


        {
            path: '/editFestivity/:id',
            name: 'editFestivity',
            component: editFestivity,
            props: true
        },


        {
            path: '/reception',
            name: 'reception',
            component: reception,
            props: true
        },

        {
            path: '/manualRegistration',
            name: 'manualRegistration',
            component: manualRegistration,
            props: true
        },

        {
            path: '/qrcodeRegistration',
            name: 'qrcodeRegistration',
            component: qrcodeRegistration,
            props: true
        },

        {
            path: '/createQRCodeFrequentlyUsers',
            name: 'createQRCodeFrequentlyUsers',
            component: createQRCodeFrequentlyUsers,
            props: true
        },

        {
            path: '/qrcodeRegistrationTest',
            name: 'qrcodeRegistrationTest',
            component: qrcodeRegistrationTest,
            props: true
        },

    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)

    // console.log(router.app.$refs);


    /*         console.log(to);
    
            console.log("Current Refs");
            console.log(router.app.$refs);
    
            Vue.nextTick(function () {
                console.log("after NextTick");
                console.log(router.app.$refs);
            }) */
})


export default router
