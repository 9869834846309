import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISmartinout {

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;

  }


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async deleteRegistration(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);


    return axios.post(this.getUrlDomain("deleteRegistration"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveRegistration(registration) {

    let formData = new FormData();

    for (var key in registration) {
      formData.append(key, registration[key]);
    }

    return axios.post(this.getUrlDomain("saveRegistration"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initDDLManualRegistration(user) {

    let formData = new FormData();

    formData.append("username", user);


    return axios.post(this.getUrlDomain("initDDLManualRegistration"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createQRCode(registration) {

    let formData = new FormData();

    for (var key in registration) {
      formData.append(key, registration[key]);
    }

    return axios.post(this.getUrlDomain("createQRCode"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APISmartinout({
  url: "http://emtool.local/public/api/smartbook/"
})
