<template>
    <v-container class="w-container v-overflow ediReminderHolidays" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                                                                            background-color: white;
                                                                                    color: black;
                                                                                    font-weight: bold;
                                                                                    padding: 4px;
                                                                                    font-size: 10px;
                                                                                    min-width:100vw;
                                                                                    border-color:#007b30">{{ titolo }}
                </div>

            </v-col>

        </v-row>



        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemCompany"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                                :value="reminder.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid" :value='item.companyid'> {{
                                    item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id='titolo' v-model="reminder.titolo" label="Titolo"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea v-model="reminder.descrizione" label="Descrizione"></v-textarea>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="d_frequenza"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;">Frequenza (MM)</label>

                                    <vue-autonumeric v-model="reminder.minute_frequency" :disabled="true"
                                        :options="'integer'" id="frequenza"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row style="margin-left:0px!important;">


                <v-col cols="12" md="12">


                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Dalle Ore</label>
                    <ion-datetime
                        minute-Values="0,01,02,03,04,05,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59"
                        class="cl-ctr-time" id="startTime" name="startTime" cancel-Text="" done-Text=""
                        display-format="HH:mm"></ion-datetime>


                </v-col>



            </v-row>


            <v-row style="margin-left:0px!important;">


                <v-col cols="12" md="12">


                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Alle Ore</label>
                    <ion-datetime
                        minute-Values="0,01,02,03,04,05,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59"
                        class="cl-ctr-time" id="endTime" name="endTime" cancel-Text="" done-Text=""
                        display-format="HH:mm"></ion-datetime>


                </v-col>



            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <v-checkbox label="Abilitato" class="cl-checkbox" v-model="reminder.enable" true-value="S"
                        false-value="N"></v-checkbox>

                </v-col>

            </v-row>

        </v-form>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopupminuti">

            <template>
                <modal name="popupMinuti" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Giorni Fine Mese</label>

                            <div id="d_minuti_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreMinuti" :options="'integer'" id="durata_minuti"
                                                ref="fld_minuti"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-minuti" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelDaysLeft" />

                                <v-img alt="" class="btn-confirm-minuti" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmDaysLeft" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupfreq">

            <template>
                <modal name="popupFrequenza" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Frequenza (MM)</label>

                            <div id="d_freq_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreFrequenza" :options="'integer'" id="durata_freq"
                                                ref="fld_frequenza"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-freq" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelFreq" />

                                <v-img alt="" class="btn-confirm-freq" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmFreq" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




    </v-container>
</template>

<script>

import apilogofirma from "../utils/logofirma/apilogofirma";
import apiusers from "../utils/users/apiusers";
import apireminderholidays from "../utils/reminderholidays/apireminderholidays";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        /*eslint-disable no-undef*/


        var pointerVue = this;

        pointerVue.$root.$children[0].totalMsgBaseRis = 0;
        pointerVue.$root.$children[0].totalMsgRefs = 0;

        this.setupButtons();


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/reminderHolidays"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#d_durata_min', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupMinuti');



            });



            $('body').on('click', '#d_frequenza', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupFrequenza');



            });




            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '#d_coord_x', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCoordX');

            });

            $('body').on('click', '.btn-confirm-coord-x', function (event) {
                console.log(event);

                pointerVue.impostazioni.coord_box_x = pointerVue.coordXFirma;

                pointerVue.$root.$children[0].setFieldActive("#d_coord_x");

                pointerVue.$modal.hide('popupCoordX');

            });

            $('body').on('click', '.btn-cancel-coord-x', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupCoordX');

            });




            $('body').on('click', '#d_coord_y', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCoordY');

            });

            $('body').on('click', '.btn-confirm-coord-y', function (event) {
                console.log(event);

                pointerVue.impostazioni.coord_box_y = pointerVue.coordYFirma;

                pointerVue.$root.$children[0].setFieldActive("#d_coord_y");

                pointerVue.$modal.hide('popupCoordY');

            });

            $('body').on('click', '.btn-cancel-coord-y', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupCoordY');

            });


        });



        if (this.$route.params.id == 0) {

            this.titolo = "Aggiungi Reminder Ferie";

            setTimeout(() => {

                this.syncAziende();

            }, 100);

        }



        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Reminder Ferie";

            setTimeout(() => {

                this.syncReminder();

            }, 100);


        }

    },


    components: {
        VueAutonumeric,
    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },


    data: () => ({

        valoreFrequenza: null,

        valoreMinuti: null,

        aziende: [],

        reminder: { companyid: "", titolo: "", descrizione: "", days_left_end_month: "", enable: "N", minute_frequency: "", from_time: "", to_time: "" },

        testoMittente: "",

        testoFirma: "",

        logoPresent: false,

        coordXFirma: null,
        coordYFirma: null,

        fieldDis: false,

        showRow: true,
        showRow2: true,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Reminder Timesheet",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),


    methods: {

        btnCancelDaysLeft: function () {

            this.$modal.hide('popupMinuti');

        },


        btnConfirmDaysLeft: function () {

            this.reminder.days_left_end_month = this.valoreMinuti;

            this.$modal.hide('popupMinuti');

        },




        btnCancelFreq: function () {

            this.$modal.hide('popupFrequenza');

        },


        btnConfirmFreq: function () {

            this.reminder.minute_frequency = this.valoreFrequenza;

            this.$modal.hide('popupFrequenza');

        },







        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "emt"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                try {

                    that.aziende = res.data.company;


                    setTimeout(() => {

                        var id_cmp_sel = window.$cookies.get("sel_filter_rem_holidays");

                        that.reminder.companyid = id_cmp_sel;


                    }, 200);


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncReminder: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apireminderholidays.getReminder(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getReminder", res);

                that.aziende = res.data.company;


                setTimeout(() => {

                    that.reminder = res.data.Result;


                    if (res.data.Result.from_time != null) {

                        $("#startTime").val(res.data.Result.from_time);

                    }

                    if (res.data.Result.to_time != null) {

                        $("#endTime").val(res.data.Result.to_time);

                    }



                }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.reminder.companyid = azienda.companyid;


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    title: "Salva",
                    width: 30

                },

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            if (that.reminder.companyid == "") {

                errore = "Specificare l'azienda";

            }

            if (errore == "") {

                if (that.reminder.titolo == "") {

                    errore = "Specificare un titolo";

                }

            }

            if (errore == "") {

                if (that.reminder.descrizione == "") {

                    errore = "Specificare la descrizione";

                }

            }

            if (errore == "") {

                if (that.reminder.minute_frequency == "") {

                    errore = "Specificare il valore della frequenza";

                }

            }

            if (errore == "") {

                if (that.reminder.minute_frequency == 0) {

                    errore = "Specificare il valore della frequenza";

                }

            }


            console.log("ORA INIZIO: ", $("#startTime").val());
            console.log("ORA FINE: ", $("#endTime").val());

            if (errore == "") {

                if ($("#startTime").val() == "") {

                    errore = "Specificare il valore dalle ore";

                }

            }

            if (errore == "") {

                if ($("#endTime").val() == "") {

                    errore = "Specificare il valore alle ore";

                }

            }


            if (errore == "") {


                //console.log($("#startTime").val());

                var str_dt_start = "";

                if ($("#startTime").val().indexOf("T") != -1) {

                    var elements = $("#startTime").val().split("T");
                    var elements2 = elements[1].split(":");

                    str_dt_start = "2023-01-01 " + elements2[0] + ":" + elements2[1] + ":00";

                }
                else {

                    str_dt_start = "2023-01-01 " + $("#startTime").val();

                }



                var str_dt_end = "";


                if ($("#endTime").val().indexOf("T") != -1) {

                    var elements3 = $("#endTime").val().split("T");
                    var elements4 = elements3[1].split(":");


                    str_dt_end = "2023-01-01 " + elements4[0] + ":" + elements4[1] + ":00";

                }
                else {

                    str_dt_end = "2023-01-01 " + $("#endTime").val();

                }


                console.log(str_dt_start);
                console.log(str_dt_end);

                var dt_start2 = new Date(str_dt_start).getTime();
                var dt_end2 = new Date(str_dt_end).getTime();

                if (dt_start2 > dt_end2) {

                    errore = "Dalle Ore deve essere maggiore rispetto a Alle Ore";


                }

            }




            if (errore == "") {

                console.log("ok");

                this.saveData();

            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },

        saveData: async function () {



            var v_token = window.$cookies.get("token");
            var that = this;




            var str_dt_start = "";
            if ($("#startTime").val().indexOf("T") != -1) {

                var elements = $("#startTime").val().split("T");
                var elements2 = elements[1].split(":");

                str_dt_start = elements2[0] + ":" + elements2[1] + ":00";

            }
            else {

                str_dt_start = $("#startTime").val();

            }
            that.reminder.from_time = str_dt_start;


            var str_dt_end = "";
            if ($("#endTime").val().indexOf("T") != -1) {

                var elements3 = $("#endTime").val().split("T");
                var elements4 = elements3[1].split(":");


                str_dt_end = "2023-01-01 " + elements4[0] + ":" + elements4[1] + ":00";

            }
            else {

                str_dt_end = "2023-01-01 " + $("#endTime").val();

            }
            that.reminder.to_time = str_dt_end;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response = await apireminderholidays.saveReminderHolidays(
                v_token,
                that.reminder,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveReminderHolidays", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();


                        router.push({
                            path: "/reminderHolidays"
                        });


                    }, 200);


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrorMsg,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        getLogo: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apilogofirma.getLogo(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getLogo", res);

                that.testoFirma = res.data.testo_firma;
                that.testoMittente = res.data.testo_mittente;

                if (res.data.nome_file != "") {

                    that.logoPresent = true;

                    var txt_image = this.getUrlDomain("public/documents/" + res.data.nome_file);


                    setTimeout(() => {

                        $(".dropify-render img").attr("src", txt_image);
                        $(".dropify-wrapper").addClass("has-preview");
                        $(".dropify-preview").show("slow");

                    }, 100);



                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }



    }

})
</script>

<style>
.ediReminderHolidays .v-label {
    font-size: 1.2em !important;
}

.ediReminderHolidays .v-input {
    font-size: 1em !important;
    padding: 10px !important;
}


.ediReminderHolidays .cl-checkbox i {
    font-size: 13px !important;
}

.ediReminderHolidays .dropify-wrapper {
    margin-left: 0px !important;
}


.ediReminderHolidays .cl-ctr-time {
    border-bottom: 1px solid #ced4da;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-left: 0px;
    margin-top: 10px;
    width: 480px;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }



    .ediReminderHolidays .cl-ctr-time {
        border-bottom: 1px solid #ced4da;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 4px;
        padding-left: 0px;
        margin-top: 10px;
        width: 339px;
    }

}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}


.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
