<template>
    <v-container id="cont_manual_reg" class="w-container v-overflow-create-qr-code createQRCode" style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:10">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#007b30">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="cognome" v-model="registration.cognome" label="Surname" :maxlength="50">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="nome" v-model="registration.nome" label="Name" :maxlength="50">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="email" v-model="registration.email" label="E-Mail" :maxlength="200">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-switch label="Frequent" v-model="selFrequent"></v-switch>

                </v-col>

            </v-row>

            <v-row v-if="selFrequent">

                <v-col cols="12" md="12">

                    <div
                        style="background:#8080808c;height:20px;padding:0;margin-left:-16px;min-width:382px;padding-left:5px;margin-bottom:10px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold;margin-left: 15px;">
                            Valid From
                        </div>
                    </div>

                </v-col>

            </v-row>


            <v-row v-if="selFrequent">

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="registration.valid_date_from" id="valid_date_from" label="Date"
                                persistent-hint readonly v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="registration.valid_date_from" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>

            <v-row v-if="selFrequent">

                <v-col cols="12" md="12">

                    <label class="v-label v-label--active theme--light"
                        style="margin-top:12px!important;margin-left: 10px;">Hour</label>
                    <ion-datetime
                        minute-Values="0,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:7px;margin-top:10px;width: 479px;"
                        id="hour_from" name="hour_from" class='hourbooking' cancel-Text="" done-Text=""
                        display-format="HH:mm">
                    </ion-datetime>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div
                        style="background:#8080808c;height:20px;padding:0;margin-left:-16px;min-width:382px;padding-left:5px;margin-bottom:10px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold;margin-left: 15px;">
                            Valid To
                        </div>
                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="registration.valid_date_to" id="valid_date_to" label="Date"
                                persistent-hint readonly v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="registration.valid_date_to" no-title @input="showPicker2 = false"
                            locale="it"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <label class="v-label v-label--active theme--light"
                        style="margin-top:12px!important;margin-left: 10px;">Hour</label>
                    <ion-datetime
                        minute-Values="0,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:7px;margin-top:10px;width: 479px;margin-left: 7px;"
                        id="hour_to" name="hour_to" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm">
                    </ion-datetime>

                </v-col>

            </v-row>




        </v-form>

    </v-container>
</template>

<script>

import apismartinout from "../utils/smartinout/apismartinout";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {


        this.$root.$children[0].checkLogout();

        /*eslint-disable no-undef*/

        var pointerVue = this;

        this.titolo = "Create QR Code";

        window.doEvent = 1;

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/diseaseHistory"
                    });
                    break;

                default:
                    break;
            }
        });



        $(document).ready(function () {

            $('body').on('ionChange', '.tipo_qrcode', function () {

                console.log(("EVENTO CLICK TIPO QRCODE"));

                pointerVue.registration.tipo_qrcode = this.value;


            });





        });





        this.setupButtons();

    },


    data: () => ({


        selFrequent: false,


        tipi_qrcode: [
            {
                id: "frequently",
                desc: "frequent"
            },
            {
                id: "temporary",
                desc: "temporary"
            }
        ],

        fieldInsert: true,

        fieldDis: false,

        showRow: true,
        showRow2: true,

        registration: { nome: "", cognome: "", email: "", tipo_qrcode: "", valid_date_from: "", valid_hour_from: "", valid_date_to: "", valid_hour_to: "" },

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Create QR Code",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    methods: {

        validateEMail: function () {

            var result;

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            console.log("MAIL: ", $("#email").val());

            if ($("#email").val() != undefined) {

                if ($("#email").val() != "") {

                    if ($("#email").val().match(validRegex)) {
                        result = true;
                    }
                    else {
                        result = false;

                    }

                }
                else {

                    result = true;

                }



            }
            else {
                result = true;

            }


            return result;

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },



        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: this.getUrlDomain("public/_lib/img/btn-create-qrcode-smartiout.png"),
                    title: "Generate And Send QR Code To User",
                    width: 30,

                },



                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },


        formatTime: function (v_time) {

            var elements = v_time.split("T");

            //console.log("ELEMENTS: ", elements);

            var txtTime = elements[1];

            txtTime = txtTime.substring(0, 5) + ":00";

            //console.log("TIME: ", txtTime);

            return txtTime;

        },


        validate() {

            var that = this;

            console.log("REGISTRATION: ", that.registration);

            var errore = "";



            if (that.registration.cognome == "") {

                errore = "Surname field required";

            }

            if (errore == "") {

                if (that.registration.nome == "") {

                    errore = "Name field required";

                }

            }

            if (errore == "") {

                if (that.registration.email == "") {

                    errore = "E-Mail field required";

                }

            }

            if (errore == "") {

                if (!that.validateEMail()) {

                    errore = "E-Mail not valid";

                }

            }


            if (that.selFrequent == true) {

                if (errore == "") {

                    if (that.registration.valid_date_from == "") {

                        errore = "Valid from date required";

                    }

                }

                if (errore == "") {

                    if ($("#hour_from").val() == "") {

                        errore = "Valid from hour required";

                    }

                }


            }


            if (errore == "") {

                if (that.registration.valid_date_to == "") {

                    errore = "Valid to date required";

                }

            }

            if (errore == "") {

                if ($("#hour_to").val() == "") {

                    errore = "Valid to hour required";

                }

            }


            if (that.selFrequent == true) {



                if (errore == "") {

                    var dateFrom2 = that.registration.valid_date_from + " " + this.formatTime($("#hour_from").val());

                    dateFrom2 = new Date(dateFrom2);

                    var dateTo = that.registration.valid_date_to + " " + this.formatTime($("#hour_to").val());

                    dateTo = new Date(dateTo);

                    if (dateFrom2 > dateTo) {

                        errore = "Valid to date and time must be greater than Valid from date and time";


                    }


                }



            }

            if (errore == "") {

                console.log("REGISTRATION: ", that.registration);

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }





        },

        getCurrentDataTime: function () {

            var v_cur_date = new Date();

            var v_date = v_cur_date.getFullYear() + "-" + v_cur_date.getMonth() + "-" + v_cur_date.getDate();

            console.log("DATE: ", v_date);

        },


        saveData: async function () {

            if (window.doEvent == 1) {

                window.doEvent = 0;

                var that = this;

                if (that.selFrequent == false) {

                    that.registration.valid_date_from = "2024-01-22";

                    that.registration.valid_hour_from = "09:00:00";

                    that.registration.tipo_qrcode = "temporary";

                }


                if (that.selFrequent == true) {

                    that.registration.valid_hour_from = this.formatTime($("#hour_from").val());
                    that.registration.valid_hour_to = this.formatTime($("#hour_to").val());

                    that.registration.tipo_qrcode = "frequently";

                }


                // that.registration.valid_hour_from = this.formatTime($("#hour_from").val());
                // that.registration.valid_hour_to = this.formatTime($("#hour_to").val());

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apismartinout.createQRCode(
                    that.registration
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from createQRCode", res);

                    that.$swal({
                        icon: "success",
                        text: "QR Code created successfully, an email has been sent to the user",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/dash"
                        });

                    }, 200);



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }





        }



    }

})
</script>

<style>
.picker-toolbar-cancel {
    background: url("https://app.smartinout.eu/public/_lib/img/btn_cancel3_smartinout.png") !important;
    background-size: 34px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.smartinout.eu/public/_lib/img/btn_confirm3_smartinout.png") !important;
    background-size: 32px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}


ion-item {
    --ion-background-color: transparent !important;
}

.v-overflow-create-qr-code {

    overflow-y: auto !important;
    padding-bottom: 115px !important;
    max-height: 100vh !important;

}


.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.createQRCode .v-input {
    font-size: 1em !important;
    font-weight: bold;
}

.createQRCode .v-select {
    font-size: 1em !important;
}

.createQRCode .v-label {
    font-size: 1em !important;
    font-weight: bold;
}


#cont_manual_reg .v-label {
    font-size: 1em;
    font-weight: bold;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 64px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
