<template>
    <v-container id="cont_manual_reg" class="w-container v-overflow-manual-registration manualRegistration"
        style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:10">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#007b30">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            
            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_tipo" style="cursor:pointer">
                        <ion-item id="itemTipo"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px;border-color: rgba(0, 0, 0, 0)!important;background-color: rgba(0, 0, 0, 0)!important">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Type </ion-label>

                            <ion-select id="tipo_incontro" name="tipo_incontro" class='tipo_incontro' cancel-Text="Closed"
                                done-Text="" :value="registration.tipo_incontro" interface="action-sheet">

                                <ion-select-option v-for="item in tipi_incontro" :key="item.id" :value='item.id'> {{
                                    item.desc }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="viewCourier">

                <v-col cols="12" md="12">

                    <div id="cont_courier" style="cursor:pointer">
                        <ion-item id="itemCourier"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px;border-color: rgba(0, 0, 0, 0)!important;background-color: rgba(0, 0, 0, 0)!important">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Courier </ion-label>

                            <ion-select id="courier" name="courier" class='courier' cancel-Text="Closed" done-Text=""
                                :value="registration.courier" interface="action-sheet">

                                <ion-select-option v-for="item in couriers" :key="item.id" :value='item.id'> {{
                                    item.desc }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="cognome" v-model="registration.cognome" label="Surname" :maxlength="50">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="nome" v-model="registration.nome" label="Name" :maxlength="50">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="email" v-model="registration.email" label="E-Mail" :maxlength="200">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="telefono" v-model="registration.telefono" label="Phone" :maxlength="50">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="viewAzienda">

                <v-col cols="12" md="12">

                    <v-text-field id="azienda" v-model="registration.azienda" label="Company" :maxlength="50">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="persona_da_incontrare" v-model="registration.persona_da_incontrare"
                        label="Person To Meet" :maxlength="200">
                    </v-text-field>

                </v-col>

            </v-row>

        </v-form>

    </v-container>
</template>

<script>

import apismartinout from "../utils/smartinout/apismartinout";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        var pointerVue = this;

        console.log("PARAMS: ", this.$route.params.id);

        this.$root.$children[0].checkLogout();

        /*eslint-disable no-undef*/

        this.titolo = "Keyboard Registration";

        window.doEvent = 1;

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/diseaseHistory"
                    });
                    break;

                default:
                    break;
            }
        });


        $(document).ready(function () {

            $('body').on('ionChange', '.courier', function () {

                console.log(("EVENTO CLICK COURIER"));

                pointerVue.registration.courier = this.value;


            });


            $('body').on('ionChange', '.tipo_incontro', function () {

                console.log(("EVENTO CLICK TIPO_INCONTRO"));

                pointerVue.registration.tipo_incontro = this.value;

                if (this.value == "Colloquio Lavoro") {

                    pointerVue.viewAzienda = false;

                    pointerVue.registration.azienda = "";

                }
                else {

                    pointerVue.viewAzienda = true;

                }

                if (this.value == "Courier") {

                    pointerVue.viewCourier = true;

                }
                else {

                    pointerVue.viewCourier = false;

                    pointerVue.registration.courier = "";

                }

            });



        });


        setTimeout(() => {

            pointerVue.initDDL();

        }, 100);


        this.setupButtons();

    },

    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({

        viewCourier: false,

        couriers: [],


        tipi_incontro: [{ id: "Meeting", desc: "Meeting" }, { id: "Colloquio Lavoro", desc: "Colloquio Lavoro" }, { id: "Courier", desc: "Courier" }],

        viewAzienda: true,


        fieldInsert: true,

        fieldDis: false,

        showRow: true,
        showRow2: true,

        registration: { nome: "", cognome: "", email: "", telefono: "", azienda: "", tipo_incontro: "Meeting", persona_da_incontare: "", courier: "" },

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Registrazione Manuale",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    methods: {

        validateEMail: function () {

            var result;

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            console.log("MAIL: ", $("#email").val());

            if ($("#email").val() != undefined) {

                if ($("#email").val() != "") {

                    if ($("#email").val().match(validRegex)) {
                        result = true;
                    }
                    else {
                        result = false;

                    }

                }
                else {

                    result = true;

                }



            }
            else {
                result = true;

            }


            return result;

        },


        chgRadio: function () {


            var tipo_selezionato = "";

            if (this.registration.tipo_incontro == "Meeting") {
                tipo_selezionato = "Colloquio Lavoro";
            }

            if (this.registration.tipo_incontro == "Colloquio Lavoro") {
                tipo_selezionato = "Meeting";
            }

            console.log("CHANGE: ", tipo_selezionato);

            if (tipo_selezionato == "Meeting") {
                this.viewAzienda = true;
            }

            if (tipo_selezionato == "Colloquio Lavoro") {
                this.viewAzienda = false;
            }


        },


        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },



        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: this.getUrlDomain("public/_lib/img/icon_save_smartinout.png"),
                    title: "Save",
                    width: 30,

                },



                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },


        addZero: function (number) {
            return (number < 10 ? '0' : '') + number;
        },

        validate() {

            var that = this;

            console.log("REGISTRATION: ", that.registration);

            var errore = "";


            if (that.registration.tipo_incontro == "Courier") {

                if (that.registration.courier == "") {

                    errore = "Courier field required";

                }


            }


            if (errore == "") {


                if (that.registration.cognome == "") {

                    errore = "Surname field required";

                }


            }



            if (errore == "") {

                if (that.registration.nome == "") {

                    errore = "Name field required";

                }

            }


            if (errore == "") {

                if (that.registration.email == "") {

                    errore = "E-Mail field required";

                }

            }

            if (errore == "") {

                if (!that.validateEMail()) {

                    errore = "E-Mail not valid";

                }

            }

            if (errore == "") {

                if (that.registration.telefono == "") {

                    errore = "Phone field required";

                }

            }

            if (errore == "") {

                if ((that.registration.tipo_incontro == "Meeting") || (that.registration.tipo_incontro == "Courier")) {

                    if (that.registration.azienda == "") {

                        errore = "Company field required";

                    }

                }

            }

            if (errore == "") {

                console.log("REGISTRATION: ", that.registration);

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }





        },


        initDDL: async function () {


            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apismartinout.initDDLManualRegistration(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initDDLManualRegistration", res);

                that.couriers = res.data.Result;

                // setTimeout(() => {


                //     that.registration.tipo_incontro = "Meeting";

                // }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        saveData: async function () {

            if (window.doEvent == 1) {

                window.doEvent = 0;

                var that = this;

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apismartinout.saveRegistration(
                    that.registration
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from saveRegistration", res);

                    if (res.data.Result == "OK") {

                        if (res.data.Operation == "Entrata") {


                            that.$swal({
                                icon: "success",
                                text: "Entry registered correctly, an email with the QR Code has been sent to you to exit",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                router.push({
                                    path: "/dash"
                                });

                            }, 200);



                        }

                        if (res.data.Operation == "Uscita") {


                            that.$swal({
                                icon: "success",
                                text: "Exit registered correctly",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                router.push({
                                    path: "/dash"
                                });

                            }, 200);



                        }


                    }
                    else {


                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }





        }



    }

})
</script>

<style>
ion-item {
    --ion-background-color: transparent !important;
}

.v-overflow-manual-registration {

    overflow-y: auto !important;
    padding-bottom: 115px !important;
    max-height: 100vh !important;

}


.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.manualRegistration .v-input {
    font-size: 1em !important;
    font-weight: bold;
}

.manualRegistration .v-select {
    font-size: 1em !important;
}

.manualRegistration .v-label {
    font-size: 1em !important;
    font-weight: bold;
}


#cont_manual_reg .v-label {
    font-size: 1em;
    font-weight: bold;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 64px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
