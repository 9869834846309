<template>
    <v-container id="conf_logo_firma" class="w-container-logo-firma" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
            background-color: white;
                    color: black;
                    font-weight: bold;
                    padding: 4px;
                    font-size: 10px;
                    min-width:100vw;
                    border-color:#007b30">{{ titolo }}</div>

            </v-col>

        </v-row>



        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="sender" label="Sender" v-model="testoMittente"></v-text-field>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <span class="files">
                        <label style="margin-left:11px;font-size:11px">File</label>
                        <input type="file" id="input-file-logo" name="logo" accept=".png,.jpg,.jpeg" class="dropify"
                            data-max-width="6610" data-max-height="6200" />
                    </span>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea id="testoFirma" label="Firma" v-model="testoFirma"></v-textarea>

                </v-col>

            </v-row>





        </v-form>






    </v-container>
</template>

<script>

import apilogofirma from "../utils/logofirma/apilogofirma";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        /*eslint-disable no-undef*/


        var pointerVue = this;

        pointerVue.$root.$children[0].totalMsgBaseRis = 0;
        pointerVue.$root.$children[0].totalMsgRefs = 0;

        this.setupButtons();

        setTimeout(() => {
            this.getLogo();
        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/pagamenti"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#d_coord_x', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCoordX');

            });

            $('body').on('click', '.btn-confirm-coord-x', function (event) {
                console.log(event);

                pointerVue.impostazioni.coord_box_x = pointerVue.coordXFirma;

                pointerVue.$root.$children[0].setFieldActive("#d_coord_x");

                pointerVue.$modal.hide('popupCoordX');

            });

            $('body').on('click', '.btn-cancel-coord-x', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupCoordX');

            });




            $('body').on('click', '#d_coord_y', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCoordY');

            });

            $('body').on('click', '.btn-confirm-coord-y', function (event) {
                console.log(event);

                pointerVue.impostazioni.coord_box_y = pointerVue.coordYFirma;

                pointerVue.$root.$children[0].setFieldActive("#d_coord_y");

                pointerVue.$modal.hide('popupCoordY');

            });

            $('body').on('click', '.btn-cancel-coord-y', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupCoordY');

            });


        });


        setTimeout(() => {

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

                pointerVue.logoPresent = false;

            });

        }, 100);


    },


    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },


    data: () => ({

        testoMittente: "",

        testoFirma: "",

        logoPresent: false,

        coordXFirma: null,
        coordYFirma: null,

        fieldDis: false,

        showRow: true,
        showRow2: true,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Logo Firma",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),


    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    title: "Salva",
                    width: 30

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            if (that.testoMittente == "") {

                errore = "Specificare un testo mittente";

            }

            if (errore == "") {


                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Specificare un file";

                }

            }

            if (errore == "") {

                if ($('#input-file-logo')[0].files.length > 0) {

                    var myfile = $('#input-file-logo')[0].files[0];

                    var lfile = myfile.name.split(".");

                    console.log("MYFILE: ", lfile[lfile.length - 1]);

                    var extension = lfile[lfile.length - 1]

                    console.log("EXT: ", extension);

                    if ((extension.toString().toUpperCase() != "PNG") && (extension.toString().toUpperCase() != "GIF") && (extension.toString().toUpperCase() != "JPG") && (extension.toString().toUpperCase() != "JPEG")) {

                        errore = "Accettati solo file PNG, GIF, JPG, JPEG";

                    }


                }

            }


            if (errore == "") {

                if (that.testoFirma == "") {

                    errore = "Specificare un testo firma";

                }

            }



            if (errore == "") {

                console.log("ok");

                this.saveData();

            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var v_file = undefined;
            if ($("#input-file-logo")[0].files.length > 0) {
                v_file = $('#input-file-logo')[0].files[0];
            }

            var response = await apilogofirma.saveLogo(
                v_token,
                v_file,
                that.logoPresent,
                that.testoFirma,
                that.testoMittente
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveLogo", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        getLogo: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apilogofirma.getLogo(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getLogo", res);

                that.testoFirma = res.data.testo_firma;
                that.testoMittente = res.data.testo_mittente;

                if (res.data.nome_file != "") {

                    that.logoPresent = true;

                    var txt_image = this.getUrlDomain("public/documents/emtool/" + res.data.nome_file);


                    setTimeout(() => {

                        $(".dropify-render img").attr("src", txt_image);
                        $(".dropify-wrapper").addClass("has-preview");
                        $(".dropify-preview").show("slow");

                    }, 100);



                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }



    }

})
</script>

<style>
#conf_logo_firma .v-label {
    font-size: 1.2em !important;
}

#conf_logo_firma .v-input {
    font-size: 1em !important;
    padding: 10px !important;
}


#conf_logo_firma .cl-checkbox i {
    font-size: 13px !important;
}

#conf_logo_firma .dropify-wrapper {
    margin-left: 0px !important;
}


.w-container-logo-firma {
    width: 500px;
    padding: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-logo-firma {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
