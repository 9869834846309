<template>
    <div id="timesheet">

        <div class="cl_cont_azi_res_ts">

            <div id="res_full_name" class="cl_res_full_name"></div>

            <div id='sec_move_dip' class="move_dip" style=''>

                <div style="font-weight:bold">
                    <img id='btn_indietro_dip' src='/emt/img/left_arrow.png'
                        style='border:0!important;height:28px;padding-top:5px;cursor:pointer;padding-right:11px;'
                        title='Indietro' />
                </div>
                <div style="font-weight:bold" id="name_dip_sel"></div>
                <div style="font-weight:bold">
                    <img src='/emt/img/right_arrow.png'
                        style='border:0!important;height:28px;padding-top:5px;cursor:pointer;padding-left:11px;'
                        title='Avanti' id='btn_avanti_dip' />
                </div>

            </div>

        </div>


        <div class="totals">
            <div class="grouptotal">
                <span class="titletotals" title="Ore Ordinarie">
                    <v-icon>mdi-account-hard-hat</v-icon>
                    <span> {{ totPresenzaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totPresenzaMese }} </span>
            </div>
            <div class="grouptotal">
                <span class="titletotals" title="Ore Assenza">
                    <v-icon>mdi-account-minus</v-icon>
                    <span> {{ totAssenzaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totAssenzaMese }} </span>
            </div>

            <div class="grouptotal">
                <span class="titletotals" title="Ore Straordinarie">
                    <v-icon>mdi-account-plus</v-icon>
                    <span> {{ totStraordinariMese }} H</span>
                </span>
                <span class="detailtotals">{{ totStraordinariMese }}</span>
            </div>
        </div>



        <v-layout d-flex align-center justify-center style="min-height: auto;margin-top:66px;">
            <v-card-actions>
                <div class="contcalendar">

                    <datepicker ref="defCalendar" @dayclick="onDayClick" @update:from-page="pageChange"
                        format="DD-MM-YYYY" v-model="date" is-expanded :first-day-of-week="2" locale="it" color="yellow"
                        is-light :attributes="attributes" />
                </div>
            </v-card-actions>
        </v-layout>
        <!--  <v-divider></v-divider> -->

        <rise-loader v-if="showSpinner" :size="'20px'" :color="'#007b30'" />
        <!--Detail -->

        <div class="mx-auto nocolor contQuanties" color="transparent" style="transform: translate(0px, -50px);}">
            <v-list-item one-line class="contDayFormatted">
                <v-list-item-content>

                    <!--   <v-list-item-title class="text-h5">
            {{ dayAbout }}
          </v-list-item-title> -->
                    <v-list-item-subtitle class="dayFormatted"
                        style="justify: center; text-align: center;font-size: 9px;" justify="center">{{ dayFormatted }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-tabs centered v-model="tab" v-if="isDipEbadge">
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab v-for="item in itemsEbadge" :key="item" @click="manageEbadge">
                    <!--  {{ item }}  -->
                    <!--  <v-list-item-icon> -->
                    <v-icon id="img_totali" v-if="item == 'Totali'">mdi-clock</v-icon>
                    <v-icon id="img_ebadge" v-if="item == 'Ebadge'">mdi-account-clock-outline</v-icon>
                    <!--  </v-list-item-icon> -->
                </v-tab>
            </v-tabs>

            <v-list v-show="tab == 0 || isDipEbadge == 0" class="transparent" style="transform: translateX(-12px);">
                <v-list-item v-for="item in forecast" :key="item.day">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>{{ item.day }}</v-list-item-title>

                    <v-list-item-subtitle class="text-right" style="min-width: 160px">

                        <div class="conSelect" v-if="item.day != 'Causale' && item.day != 'Totale'">

                            <v-text-field readonly type="text" :min="0" :max="10"
                                v-if="item.day != 'Causale' && item.day != 'Totale'" class="selectHour"
                                @change="updateSumDay" :disabled="!day_editable" :items="itemsQuantity" label="" dense
                                :value="item.quantity + ' H'" :ref="'combo' + item.day"></v-text-field>

                            <div class="addbuttons">
                                <div :data-id="item.day" v-on:click="sumHours" class="plus" :disabled="!day_editable">+
                                </div>
                                <div :data-id="item.day" v-on:click="sumHours" class="minus" :disabled="!day_editable">-
                                </div>
                            </div>

                        </div>

                        <v-select v-if="item.day == 'Causale'" @change="updateSumDay" class="selectHour"
                            :disabled="!day_editable" :items="causali" item-value="idtypeactivity"
                            item-text="description" label="" dense v-model="item.quantity"></v-select>

                        <v-text-field v-if="item.day == 'Totale'" class="selectHour totaleCombo" :disabled="true"
                            label="" dense :value="item.quantity + ' H'"></v-text-field>
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>

            <!--Ebadge Start -->

            <v-list v-show="tab == 1" class="transparent" style="transform: translateX(-12px);">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-arrow-right-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title> Entrata</v-list-item-title>

                    <v-list-item-subtitle class="text-right" style="min-width: 160px">
                        <v-text-field class="selectHour totaleCombo" :disabled="true" label="" dense
                            :value="entrata1 + ' '"></v-text-field>
                    </v-list-item-subtitle>
                </v-list-item>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-arrow-left-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title> Uscita</v-list-item-title>

                    <v-list-item-subtitle class="text-right" style="min-width: 160px">
                        <v-text-field class="selectHour totaleCombo" :disabled="true" label="" dense
                            :value="uscita1 + ' '"></v-text-field>
                    </v-list-item-subtitle>
                </v-list-item>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-arrow-right-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title> Entrata</v-list-item-title>

                    <v-list-item-subtitle class="text-right" style="min-width: 160px">
                        <v-text-field class="selectHour totaleCombo" :disabled="true" label="" dense
                            :value="entrata2 + ' '"></v-text-field>
                    </v-list-item-subtitle>
                </v-list-item>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-arrow-left-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title> Uscita</v-list-item-title>

                    <v-list-item-subtitle class="text-right" style="min-width: 160px">
                        <v-text-field class="selectHour totaleCombo" :disabled="true" label="" dense
                            :value="uscita2 + ' '"></v-text-field>
                    </v-list-item-subtitle>
                </v-list-item>



                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-expand-all</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title> Totale</v-list-item-title>

                    <v-list-item-subtitle class="text-right" style="min-width: 160px">
                        <v-text-field class="selectHour totaleCombo" :disabled="true" label="" dense
                            :value="forecast[4].quantity + ' H '"></v-text-field>
                    </v-list-item-subtitle>
                </v-list-item>




            </v-list>
            <!--Ebadge END -->

            <!--  <v-divider></v-divider> -->

            <!--     <v-card-actions>
      <v-btn text>
        Full Report
      </v-btn>
    </v-card-actions> -->
        </div>

        <v-row justify="center" class="rowStatus" style="align-items:center;">
            <span>STATO</span>
            <span class="spanStatus">
                <v-tooltip v-if="curStatus == 'Open'" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon color="black" size="30" v-on="on">mdi-account-lock-open</v-icon>
                    </template>
                    <span>{{ curStatus }}</span>
                </v-tooltip>

                <v-tooltip v-if="curStatus == 'Close'" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon size="30" color="black" v-on="on">mdi-account-lock</v-icon>
                    </template>
                    <span>{{ curStatus }}</span>
                </v-tooltip>

                <v-tooltip v-if="curStatus == 'For approval'" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon size="30" color="black" v-on="on">mdi-account-arrow-right</v-icon>
                    </template>
                    <span>{{ curStatus }}</span>
                </v-tooltip>

                {{ "" }}
            </span>
        </v-row>

        <!--Detail End -->

        <!--Admin dialog START-->
        <!--  <div ref="ok" id="ok"></div> -->
        <template>
            <v-row justify="center">
                <v-dialog v-model="admindialog" persistent max-width="600px">
                    <template>
                        <!--    <v-btn
            color="primary"

            v-bind="attrs"
            v-on="on"
          >
            Scegli dipendente
          </v-btn> -->
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Seleziona dipendente</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>

                                    <v-col cols="12" sm="6">
                                        <v-select v-model="currAzienda" v-on:change="syncArrayDipendenti"
                                            :items="aziende" label="Azienda*" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="dipendenti" label="Risorsa" v-model="currDipendente"
                                            item-value="v_res_id" item-text="fld_name" @change="changeDip"></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <small></small>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="admindialog = false; syncMonth();">
                                Close
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="admindialog = false; syncMonth();">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!--Admin dialog END -->

        <!---OK START-->
        <!--
<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="purple"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open In
        </v-btn>
      </template>
      <v-list style="max-height: 309px;overflow: auto;">
        <v-subheader>Open in</v-subheader>
        <v-list-item
          v-for="tile in tiles"
          :key="tile.title"
          @click="sheet = false"
        >
          <v-list-item-avatar>
            <v-avatar
              size="22px"
              tile
            >
              <img
                :src="`https://cdn.vuetifyjs.com/images/bottom-sheets/${tile.img}`"
                :alt="tile.title"
              >
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>{{ tile.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template> -->

        <!---OK END-->

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24" style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                                    @click="manageClickAziende(tile)">

                                    <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>

                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">

                        <div class="headerSheetAziende" fixed style="position:fixed;">

                            Dipendenti
                            <v-btn class='closeSheet' icon @click="sheetDipendenti = !sheetDipendenti">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />
                                
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>

                            </v-toolbar>
                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip)">

                                    <v-img alt="" src="@/assets/profilo3.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemNumDip'>{{ objDip.n_dip }}</v-list-item-title>

                                    <v-list-item-title class='itemNameDip'>{{ objDip.full_name }}</v-list-item-title>


                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">

                <v-bottom-sheet v-model="sheetStatus">
                    <v-sheet class="text-center" height="300px" style="padding-bottom:100px;">

                        <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                            <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>

                        <!-- <v-btn class="mt-6" text color="yellow" @click="sheetStatus = !sheetStatus">
                        close
                    </v-btn> -->

                        <div class="py-3">
                            Stato Timesheet attuale : {{ curStatus }}

                        </div>

                        <v-list-item
                            v-show="curStatus == 'Open' && tile.title != 'Aperto' || curStatus == 'For approval' && tile.title != 'For approval' || curStatus == 'Close' && tile.title != 'Chiuso'"
                            class="text-left" v-for="tile in allStatus" :key="tile.title"
                            @click="changeStatus(tile.title)">
                            <v-list-item-avatar>

                                <v-avatar size="32px" tile>
                                    <v-icon :color="tile.color">{{ tile.icon }}</v-icon>

                                    <!--  <img
                :src="`https://cdn.vuetifyjs.com/images/bottom-sheets/${tile.img}`"
                :alt="tile.title"
              > -->
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-title>{{ tile.title }}</v-list-item-title>
                        </v-list-item>

                    </v-sheet>
                </v-bottom-sheet>

            </div>
        </template>

    </div>
</template>

<script>
var env = process.env.NODE_ENV;
if (env != "development") console.log = function () { };

import $ from 'jquery';
import _ from 'lodash';

/*   import '@ionic/core/css/ionic.bundle.css';
 */

/* import Datepicker from "vuejs-datepicker";
import {en, de, es , it} from 'vuejs-datepicker/dist/locale' */
/* import VCalendar from "v-calendar"; */
//import Vue from "vue";
// Use v-calendar & v-date-picker components
/* Vue.use(VCalendar, {

}); */
//import Calendar from 'v-calendar/lib/components/calendar.umd'
import datepicker from "v-calendar/lib/components/date-picker.umd";
import RiseLoader from "vue-spinner/src/RiseLoader.vue";
import router from ".././router";
import {
    bus
} from "../main";

import apitimesheet from "../utils/timesheet/apitimesheet";


export default {

    watch: {
        date: {
            handler: function () {
                // console.log(this.date)
            },
            deep: true,
        },
    },

    updated() {

    },

    mounted: async function () {


        this.$root.$children[0].checkLogout();

        window.numEventDipAvanti = 0;
        window.numEventDipIndietro = 0;


        //  this.$root.$children[0].$refs.mainAziende.openAziende();

        this.dipendenti = this.$root.$children[0].dipendenti;
        this.aziende = this.$root.$children[0].aziende;

        console.log("AZI 2: ", this.aziende);

        this.isDipEbadge = parseInt(window.$cookies.get("is_ebadge"));

        /*  let dipReq = window.location.href.includes("?dip=1");

         if (dipReq) {
             this.sheetAziende = true;
         } */

        // let urlParams = new URLSearchParams(window.location.search);

        // console.log( "hasDip" , window.location.href ) ; // true

        /* setTimeout(() => {

         $(".is-today").focus().trigger("click");
         $(".is-today").focus().trigger("click");

        }, 4000); */

        bus.$on("menuClickEvent", (data) => {

            switch (data) {

                case "loadAziende":

                    break;

                case "showaziende":

                    this.sheetAziende = true;
                    break;
            }
        });

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_aziende":
                    //this.admindialog = true ;
                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);
                    break;
                case "btn_status":
                    this.sheetStatus = true;

                    break;

                case "btn_refr_tms":
                    this.resetDay();
                    this.syncMonth();
                    break;
                case "btn_send_tms":
                    //alert("btn_add_tms");
                    //request
                    // this.$swal({ icon: "success", text: "Do you want to send " });
                    var $mesi = [
                        "",
                        "gennaio",
                        "febbraio",
                        "marzo",
                        "aprile",
                        "maggio",
                        "giugno",
                        "luglio",
                        "agosto",
                        "settembre",
                        "ottobre",
                        "novembre",
                        "dicembre",
                    ];
                    var text = $mesi[this.curMonth] + " - " + this.curYear;

                    console.log("this.curStatus", this.curStatus)
                    var statonum = 1;
                    // (1 - Aperto 2 - In Approvazione 3 - Chiuso) (obbligatorio)

                    if (this.curStatus == 'Open') statonum = 1;
                    if (this.curStatus == 'Close') statonum = 3;
                    if (this.curStatus == 'For approval') statonum = 2;

                    if (this.aa == 'Y' || this.su == 'Y') {

                        console.log(statonum);

                        this.sheetStatus = true;
                        /*   this.$swal
                             .fire({
                             title: "Sei sicuro?",
                             html: "Vuoi cambiare lo stato al  timesheet <h4>" + text + " ?</h4> ",
                             icon: "warning",

                               input: 'checkbox',
                               inputValue: statonum,
                                 inputOptions: {
                                     'Stati': {
                                     1: 'Aperto',
                                     3: 'Chiuso',
                                     2: 'In attesa',
                                     },
                                 },
                                 inputPlaceholder: 'Seleziona lo stato',

                             showCancelButton: true,
                             confirmButtonColor: "#3085d6",
                             cancelButtonColor: "#d33",
                             confirmButtonText: "Yes!",
                             })
                             .then((result) => {
                             if (result.value == true) {
                                 //console.log (true);

                                 var response = this.updateStatusMonth(2).then((res) => {
                                 if (res.status == 200) {
                                     // this.$swal("Il timesheet è stato inviato correttamente");
                                     this.$swal({
                                     icon: "success",
                                     text: "Il timesheet è stato inviato correttamente",
                                     });
                                     this.syncMonth();
                                 } else {
                                     this.$swal({
                                     icon: "error",
                                     text: "Il timesheet non è stato inviato correttamente",
                                     });
                                 }
                                 });

                                 console.log("Response", response);
                             }
                             }); */

                    } else {
                        this.$swal
                            .fire({
                                title: "Sei sicuro?",
                                html: "Vuoi inviare il timesheet <h4>" + text + " ?</h4> ",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes!",
                            })
                            .then((result) => {
                                if (result.value == true) {
                                    //console.log (true);

                                    var response = this.updateStatusMonth(2).then((res) => {
                                        if (res.status == 200) {
                                            // this.$swal("Il timesheet è stato inviato correttamente");
                                            this.$swal({
                                                icon: "success",
                                                text: "Il timesheet è stato inviato correttamente",
                                            });
                                            this.syncMonth();
                                        } else {
                                            this.$swal({
                                                icon: "error",
                                                text: "Il timesheet non è stato inviato correttamente",
                                            });
                                        }
                                    });

                                    console.log("Response", response);
                                }
                            });
                    }

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });




        $(document).ready(function () {

            $("#img_totali").attr("title", "Ore Timesheet");
            $("#img_ebadge").attr("title", "Ore Ebadge");

        });













    },

    mountedOLD() {
        //console.log ( "CAlendar" ,this.$refs.defCalendar );
        //var pointerTHis = this ;

        $("body").on("click", ".v-menu__content", function () {

            //$(this).hide();
            //  $(".selectHour").trigger("blur");
            //      window.pointerThis = pointerTHis ;

            //this.$refs.comboOrdinari.click();
            //      console.log ( "all refs" , window.refs );
            //$(".v-select--is-menu-active").find(".v-icon").trigger("click");

            /* $(".v-select--is-menu-active").find(".v-select__slot").trigger("blur");

            setTimeout(() => {
                $("body").trigger("click");

            }, 100); */

            //$(".v-select__slot").trigger("click");
            //window.refs.comboOrdinari.hide();

            //   this.$refs.combo.blur();

        });
        //$(".menu__content").hide();
        /*   document.getElementsByClassName("v-menu__content")[0].addEventListener("click",() => {

              alert(1);
            }); */

        /*
              var template = `<ion-list>
                    <ion-item>
                      <ion-label>Gender</ion-label>
                      <ion-select :v-model="sheet" placeholder="Select One" interface="action-sheet">
                        <ion-select-option  value="f">Female</ion-select-option>
                        <ion-select-option value="m">Male</ion-select-option>
                        <ion-select-option value="m">Male</ion-select-option>
                        <ion-select-option value="m">Male</ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-list>`;
                  //document.getElementsByClassName("ionicdiv").appendChild(template);

                  setTimeout(() => {
                      //const  div = document.getElementById('ok');

                      const div = this.$refs.ok ;
                      console.log ("o",div);

                    //div.insertAdjacentHTML('afterbegin' , template);

                  }, 2000); */

        //console.log ( this.$root.showProva ) ;
        //   console.log ("here we go" , window.App ) ;
        //   this.syncMonth();
        /*         var datesOk =   [
                        new Date( '2021-12-13'),
                        new Date( '2021-12-14'),
                ];

            this.todos = [
                {
                    description: 'Festivity',
                    isComplete: false,
                    dates: datesOk,
                    color: 'red',
                },
                ]; */
        //    this.dayFormatted = this.date ;
        /*   setTimeout(() => {
          this.$refs.defCalendar.$emit("dayclick");

          //document.getElementsByClassName("vc-highlights")[0].click();
          //this.$refs.defCalendar.$emit("dayclick")  ;
        }, 1200); */
        //   console.log ( this.date );
    },
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        this.$root.$children[0].buttonsTop = [];

        //to manage if admin
        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].curDipTop = namesurname;
        this.$root.$children[0].curDipOreTop = "";
        this.$root.$children[0].curDipOreTopLabel = "";

        bus.$off("bottomIconClickEvent");
        bus.$off("menuClickEvent");
    },

    methods: {

        loadAziendaUserLogged: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apitimesheet.getAziendaByUser(
                v_token
            ).then((res) => {

                console.log("res from getAziendaByUser", res);


                $("#res_full_name").html("<b>" + res.data.Azienda + "</b>");




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        funcEvent: function () {


            var that = this;

            $("#btn_indietro_dip").on("click", function (opt) {

                console.log(opt);


                if (window.numEventDipIndietro == 0) {

                    console.log("DIPENDENTI: ", that.dipendenti);

                    console.log("CUR RESOURCE: ", that.curResource);

                    window.numEventDipIndietro = window.numEventDipIndietro + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos - 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }





            });



            $("#btn_avanti_dip").on("click", function (opt) {

                console.log(opt);

                if (window.numEventDipAvanti == 0) {


                    window.numEventDipAvanti = window.numEventDipAvanti + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos + 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }

            });








        },



        hideShowSecNav: function (v_show) {

            if (v_show) {

                $("#btn_indietro_dip").show();
                $("#name_dip_sel").show();
                $("#btn_avanti_dip").show();

            }
            else {


                $("#btn_indietro_dip").hide();
                $("#name_dip_sel").hide();
                $("#btn_avanti_dip").hide();

            }



        },


        manageEbadge: function () {
            setTimeout(() => {
                // alert ( this.tab );

            }, 300);
        },

        resetDay: function () {
            this.date = "";

        },
        changeStatus: async function (status) {
            console.log(status);
            this.sheetStatus = false;
            var statusInt = 0;

            if (status == "Aperto") statusInt = 1;
            if (status == "For approval") statusInt = 2;
            if (status == "Chiuso") statusInt = 3;
            console.log("Stato int", statusInt);

            //this.$root.$children[0].showProgress = true ;
            this.showSpinner = true;

            var response = await this.updateStatusMonthAdmin(statusInt).then((res) => {
                if (res.status == 200) {
                    // this.$swal("Il timesheet è stato inviato correttamente");
                    this.$swal({
                        icon: "success",
                        text: "Il timesheet è stato aggiornato correttamente",
                        showConfirmButton: false,
                        timer: 500
                    });
                    this.syncMonth();
                } else {
                    this.$swal({
                        icon: "error",
                        text: "Il timesheet non è stato aggiornato correttamente",
                    });
                }
            });
            //this.$root.$children[0].showProgress = false ;
            this.showSpinner = false;

            console.log("Response", response);

        },

        clearSearch() {
            this.search = "";
        },
        clearSearchDipendenti() {
            this.searchDipendenti = "";
        },

        sumHours: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");
                var disabled = event.target.attributes.getNamedItem('disabled');

                console.log("PO", this.piano_orario);
                console.log("curDay", this.curDay);

                var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                //console.log ("dw", strDay.getDay() );
                var mapDay = {
                    "0": "domenica",
                    "1": "lunedi",
                    "2": "martedi",
                    "3": "mercoledi",
                    "4": "giovedi",
                    "5": "venerdi",
                    "6": "sabato"
                };

                var po = this.piano_orario[0];
                var dayLiteral = mapDay[strDay.getDay()];

                var oretoday = parseFloat(po[dayLiteral]);

                var max = 10;
                max = oretoday;
                var index = 0;
                var newQuant;
                if (dataid == 'Ordinari') index = 0;
                if (dataid == 'Straordinari') index = 1;
                if (dataid == 'Assenza') index = 2;

                console.log("quantita", this.forecast[index].quantity);

                if (disabled == null) {

                    if (operation == '+')
                        newQuant = parseFloat(this.forecast[index].quantity) + 0.5;
                    else
                        newQuant = parseFloat(this.forecast[index].quantity) - 0.5;

                    if (newQuant <= max && operation == '+') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    if (newQuant >= 0 && operation == '-') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    var diff = 0;
                    //adapta absence with ordinary , user cliecked ordinari so check if more than usual
                    if (dataid == 'Ordinari') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[2].quantity = diff.toString();
                        }
                    }

                    if (dataid == 'Assenza') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[0].quantity = diff.toString();
                        }
                    }

                    this.updateSumDay();
                }

            }

        },

        manageClickDipendenti: function (dipendente) {
            this.sheetDipendenti = false;

            window.numEventDipAvanti = 0;
            window.numEventDipIndietro = 0;

            this.currDipendente = dipendente.resourceid;
            //  alert ( dipendente );
            this.resetDay();
            this.changeDip();
            setTimeout(() => {
                this.syncMonth();

                //console.log( "dipobj" ,this.currDipendenteObj);
                this.isDipEbadge = parseInt(this.currDipendenteObj.is_ebadge);

                this.entrata1 = "";
                this.entrata2 = "";
                this.uscita1 = "";
                this.uscita2 = "";

            }, 100);
            /*   this.syncArrayDipendenti();
              console.log ("CurAzienda",this.currAzienda );
              console.log(this.dipendenti);

              this.sheetDipendenti = true ; */

        },

        manageClickAziende: async function (azienda) {

            var that = this;

            var v_token = window.$cookies.get("token");

            this.currAzienda = azienda;

            // console.log("CUR YEAR", this.curYear);
            // console.log("CUR MONTH", this.curMonth);

            this.$root.$children[0].showProgress = true;

            var response = await apitimesheet.getDipendenti(
                azienda,
                v_token,
                this.curYear,
                this.curMonth
            ).then((res) => {

                this.$root.$children[0].showProgress = false;

                //that.$root.$children[0].showProgress = false;
                console.log("res from getDipendenti", res);


                var arrDipendenti = [];

                for (var dip of res.data.Dipendenti) {

                    arrDipendenti.push(dip);

                }


                this.dipendenti = arrDipendenti;

                setTimeout(() => {
                    this.sheetAziende = false;
                    this.sheetDipendenti = true;
                }, 300);



                //  console.log("ARR DIP: ", arrDipendenti);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }


            );


            // this.currAzienda = azienda;
            // await this.syncArrayDipendenti();

            // console.log("CurAzienda", this.currAzienda);
            // console.log(this.dipendenti);

            // setTimeout(() => {
            //     this.sheetAziende = false;
            //     this.sheetDipendenti = true;
            // }, 300);

        },


        changeDip: function (selectObj) {
            console.log(selectObj);
            /*  console.log("Oggetto :", selectObj);
             console.log(selectObj.src);

             console.log(this.currDipendente);
             console.log(this.dipendenti); */

            // var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    //       selNameDip = dip.fld_name;

                }
            }

            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        updateStatusMonthAdmin: async function (status) {

            console.log("currDipObj", this.currDipendenteObj);

            //    return false ;// eslint-disable no-unreachable

            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");

            if (this.currDipendenteObj.resourceid !== undefined) {
                //alert (  this.currDipendenteObj.resourceid  );
                system = this.currDipendenteObj.v_res_id;
                token = this.currDipendenteObj.v_user;
                user_id = this.currDipendenteObj.v_user_id;

            }

            var success = 1;
            var response = null;
            try {
                response = await this.$api.tsUpdateStatus(
                    token,
                    system,
                    user_id,
                    status,
                    this.curYear,
                    this.curMonth
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }
            console.log(response, status, success, token, system, user_id);
            return response;
        },

        updateStatusMonth: async function (status) {
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");
            //  var username = window.$cookies.get("username");
            //system => resourceid
            //token_system=>pass encodata
            //username

            var success = 1;
            var response = null;
            try {
                response = await this.$api.tsUpdateStatusApproval(
                    token,
                    system,
                    user_id,
                    status,
                    this.curYear,
                    this.curMonth
                );
                /* .then((res) => {
                                console.log (res.data);
                                console.log (success);
                            }); */
            } catch (error) {
                success = 0;
                console.log(error);
            }

            console.log(response, status, success);

            return response;
        },

        setupNavTop: async function () {
            // this.setupNavBottomDone = true ;
            /*   var disabled = false;
              if (this.curStatus == "Close" || this.curStatus == "For Approval") {
                disabled = true;
              } */

            var icon = "mdi-account-lock-open";
            if (this.curStatus == "Close") icon = "mdi-account-lock";
            if (this.curStatus == "For approval") icon = "mdi-account-arrow-right";

            var color = this.colorsStati[this.curStatus];

            console.log("Color status :", color);

            var buttonsTop = [{
                text: "",
                icon: icon,
                link: "",
                id: "btn_status",
                disabled: false,
                color: color
            }];

            this.$root.$children[0].buttonsTop = buttonsTop;
            //console.log ( icon );
        },

        setupNavBottom: async function () {


            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            this.setupNavBottomDone = true;
            var disabled = false;
            if (this.curStatus == "Close" || this.curStatus == "For approval") {
                disabled = true;
            }

            if (this.aa == 'Y' || this.su == 'Y') {
                disabled = false;
            }

            var icon = "mdi-account-lock-open";
            if (this.curStatus == "Close") icon = "mdi-account-lock";
            if (this.curStatus == "For approval") icon = "mdi-account-arrow-right";

            console.log(icon);

            setTimeout(() => {

                var pulsantis = [


                    {
                        text: "Ricarica",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/refresh-emt.png",
                        link: "",
                        id: "btn_refr_tms",
                        disabled: false,
                        title: "Aggiorna",
                        width: 30
                    },



                ];


                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                            link: "",
                            id: "btn_send_tms",
                            disabled: disabled,
                            title: "Cambia Stato",
                            width: 30
                        },



                    );



                }
                else {

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                            link: "",
                            id: "btn_send_tms",
                            disabled: disabled,
                            title: "Invia Per Approvazione",
                            width: 30
                        },



                    );

                }


                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                    pulsantis.push(

                        {
                            text: "",
                            icon: "mdi-domain",
                            link: "/dash",
                            id: "btn_aziende",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/aziende-emt.png",
                            title: "Aziende",
                            width: 30

                        }




                    );
                }


                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 10);
        },

        resetSumDay: async function () {
            /*  this.totPresenzaMese = monthPresenza;
                  this.totStraordinariMese = monthStraordinary; */

            this.forecast[0].quantity = "";
            this.forecast[1].quantity = "";
            this.forecast[2].quantity = "";
            this.forecast[3].quantity = "";
            this.forecast[4].quantity = "";
        },

        roundstaff: function (val) {
            console.log(val);

            return val.toFixed(2);

        },
        updateSumTotals: function () {
            //update all
            var result = Object.entries(this.monthArray);
            //   console.log ("qui" ,  result );
            var monthPresenza = 0;
            var monthStraordinary = 0;
            var monthAssenza = 0;
            //var monthAssenza = 0 ;

            var ordinary = "";
            var straordinary = "";
            var assenza = "";
            //var assenza = "";

            for (var dat of result) {
                ordinary = dat[1].hour_ordinario;
                straordinary = dat[1].hour_straordinario;
                assenza = dat[1].hour_activity;

                if (ordinary > 0) monthPresenza += parseFloat(ordinary);
                if (straordinary > 0) monthStraordinary += parseFloat(straordinary);
                if (assenza > 0) monthAssenza += parseFloat(assenza);
            }
            this.totPresenzaMese = this.roundstaff(monthPresenza);
            this.totStraordinariMese = this.roundstaff(monthStraordinary);
            this.totAssenzaMese = this.roundstaff(monthAssenza);

            var totali = parseFloat(monthPresenza) + parseFloat(monthStraordinary);

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = this.roundstaff(totali) + " H";

        },
        updateSumDay: async function () {
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            var username = window.$cookies.get("username");
            console.log(username);

            var ord = this.forecast[0].quantity;
            var straord = this.forecast[1].quantity;
            var ass = this.forecast[2].quantity;
            var id_ass = this.forecast[3].quantity;

            if (ass == 0 || ass == "0") {
                id_ass = "";
                this.forecast[3].quantity = "";

            }

            this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);

            console.log("meseArray", this.monthArray);

            this.monthArray[this.curDay].hour_ordinario = ord;
            this.monthArray[this.curDay].hour_activity = ass;
            this.monthArray[this.curDay].hour_straordinario = straord;
            this.monthArray[this.curDay].idtypeactivity = id_ass;

            //trial
            this.updateSumTotals();

            //last modify update calendar icons
            this.syncMOnthIcons();

            //Call Ws tsUpdateDay async without blocking ui
            //v_ore_presenza,v_ore_assenza,v_ore_straordinari,v_id_assenza

            //if admin os su
            if (this.currDipendenteObj.resourceid !== undefined) {

                system = this.currDipendenteObj.v_res_id;
                token = this.currDipendenteObj.v_user;
                //user_id = this.currDipendenteObj.v_user_id;

            }

            try {
                var response = this.$api
                    .tsDayUpdate(token, system, this.curDay, this.curYear, this.curMonth, ord, ass, straord, id_ass)
                    .then((res) => {
                        console.log(res);
                    });
                console.log(response);
            } catch (error) {
                console.log(error);
            }
        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            // this.currDipendente = "";
            // this.$root.$children[0].curDipTop = "";

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            console.log("Elenco aziende:", this.aziende);
            // this.currAzienda
            //console.log ( "aziendeOK" , aziende );
            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    //console.log ( dip.fld_name );
                    //  console.log ( dip );
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        getParamsApi() {
            var curDip = parseInt(this.currDipendente);
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            if (curDip > 0) {
                console.log("curDIp", curDip, "this.dipendenti", this.dipendenti);
                //for( var dip in this.dipendenti  )
                for (const [key, dip] of Object.entries(this.dipendenti)) {
                    console.log("key", key);
                    if (parseInt(dip.resourceid) == curDip) {
                        console.log("dip", dip, "token", token, "system", system);
                        token = dip.v_user;
                        system = dip.resourceid;

                    }
                }

            }
            console.log({
                "token": token,
                "system": system
            });
            return {
                "token": token,
                "system": system
            };
        },

        syncMOnthIcons: function () {
            var arrTimesheet = this.monthArray;

            var todos = [];

            //var sabdom = [] ;
            for (var day of Object.entries(arrTimesheet)) {
                // console.log("giorno", day[1]);
                var dayCur = day[1];
                var strDay = new Date();

                //check plan hour
                var today = 8;
                if (dayCur.hour_ordinario < today && (dayCur.day_working != "NOT WORKING" &&
                    (dayCur.desc_day != "Sab" && dayCur.desc_day != "Dom"))) {
                    //  console.log (1);

                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.hour_ordinario /* "Ordinari  " + dayCur.hour_ordinario  */ /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "green",
                        isComplete: false,
                        bar: {
                            color: "red"
                        },
                        dot: false
                    });

                }

                //Green for workingday
                if (dayCur.hour_ordinario > 0) {

                    console.log("Ordinari");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Ordinari  " + dayCur.hour_ordinario /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "green",
                        isComplete: true,
                        dot: true
                    });

                }

                //illness
                //console.log ("ok" , this.causali [ dayCur.idtypeactivity]);
                if (dayCur.hour_activity > 0) {

                    console.log("fesilnesst");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Assenze " /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "red",
                        isComplete: false,
                        dot: true
                    });
                    //   datesOk.push ( strDay );
                    //  descOk.push ( dayCur.desc_festività );
                }

                if (dayCur.is_festivo) {
                    console.log("fest");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.desc_festività,
                        dates: [strDay],
                        color: "red",
                        isComplete: false,
                        dot: true
                    });
                    //   datesOk.push ( strDay );
                    //  descOk.push ( dayCur.desc_festività );
                }
                if (
                    dayCur.day_working == "NOT WORKING" &&
                    (dayCur.desc_day == "Sab" || dayCur.desc_day == "Dom")
                ) {
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Non lavorativo",
                        dates: [strDay],
                        color: "blue",
                        isComplete: false,
                        dot: true
                    });
                }
            }
            this.todos = todos;

        },

        syncMonth: async function () {

            var params = this.getParamsApi();

            /*          var token = window.$cookies.get("token");
                        var system = window.$cookies.get("system"); */

            var token = params.token;
            var system = params.system;

            //   console.log ( system );
            this.showSpinner = true;
            var response;
            var arrTimesheet = [];

            try {
                response = await this.$api
                    .GetTimesheet(token, system, this.curYear, this.curMonth)
                    .then((res) => {
                        arrTimesheet = res.data.Timesheet;
                        console.log("Timesheet", arrTimesheet);

                        this.monthArray = arrTimesheet;

                        var result = Object.entries(arrTimesheet);
                        console.log(result);

                        var info_month = arrTimesheet.info_month;

                        console.log("CUR AZIENDA: ", this.currAzienda);

                        // $("#res_full_name").html("Risorsa:&nbsp;&nbsp;&nbsp;<b>" + info_month.full_name + "</b>");


                        if (!this.setupNavBottomDone) {
                            this.su = arrTimesheet.u_complete;
                            this.aa = arrTimesheet.az_complete;

                        }

                        this.curStatus = info_month.desc_stato;
                        this.curReadOnly = info_month.editable;
                        this.piano_orario = arrTimesheet.piano_orario;

                        console.log("info_month", info_month);

                        var causali = arrTimesheet.causali;
                        console.log("causali", causali);
                        var arrCausali = [];

                        for (var causale of causali) {
                            //arrCausali[causale.idtypeactivity] = causale.description  ;
                            arrCausali.push(causale.description);
                        }
                        // console.log ( arrCausali );
                        this.causali = causali;

                        //aziende
                        var arrAziende = [];
                        var arrDipendenti = [];
                        console.log(arrDipendenti);

                        var aziende = Object.entries(arrTimesheet.aziende);
                        //console.log ( "aziendeOK" , aziende );
                        for (var az of aziende) {
                            console.log("az", az);
                            arrAziende.push(az[1].fld_name);

                        }

                        arrAziende.sort();

                        if (arrAziende.length > 1 && !this.setupNavBottomDone) {

                            //attention
                            this.aziende = arrAziende;
                            this.dipendenti = arrDipendenti;
                            this.globalArrAziende = aziende;

                            /*  this.dipendenti =  this.$root.$children[0].dipendenti;
                              this.aziende =  this.$root.$children[0].aziende;
                               this.globalArrAziende =  this.$root.$children[0].globalArrAziende; */

                        }

                        console.log("arraz", arrAziende);
                        console.log("globalArrAziende", this.globalArrAziende);
                        //this.causali = arrCausali;
                        //Start
                        //var datesOk =   [ ];
                        //  var descOk =   [ ];

                        console.log("SYNC_MONTH DIPENDENTI: ", this.dipendenti);
                        console.log("SYNC_MONTH DIPENDENTI LEN: ", this.dipendenti.length);

                        this.curResource = info_month.full_name;

                        if (this.dipendenti.length == 0) {

                            this.hideShowSecNav(false);

                        }

                        if (this.dipendenti.length > 0) {

                            this.hideShowSecNav(true);

                            $("#name_dip_sel").html(info_month.full_name);

                            // abilito le frecce a seconda se sono sul primo o l'ultimo elemento

                            var cur_pos = 0;
                            var find_pos = 0;

                            for (var dip of this.dipendenti) {

                                if (info_month.full_name == dip.fld_name) {
                                    find_pos = cur_pos;
                                }

                                cur_pos = cur_pos + 1;

                            }

                            $("#btn_indietro_dip").show();
                            $("#btn_avanti_dip").show();

                            if (find_pos == 0) {

                                $("#btn_indietro_dip").hide();

                            }

                            console.log("FIND POS: ", find_pos);
                            console.log("DIP LEN: ", this.dipendenti.length);

                            if (find_pos == (this.dipendenti.length - 1)) {

                                $("#btn_avanti_dip").hide();

                            }




                        }


                        this.syncMOnthIcons();
                        // var todos = [];

                        // //var sabdom = [] ;
                        // for (var day of result) {
                        //  // console.log("giorno", day[1]);
                        //   var dayCur = day[1];
                        //   var strDay = new Date();

                        // //Green for workingday
                        // if (dayCur.hour_ordinario>0) {

                        // console.log("Ordinari");
                        // strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                        // todos.push({
                        //     description: "Ordinari  " + dayCur.hour_ordinario  /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        //     dates: [strDay],
                        //     color: "green",
                        //     isComplete: true,
                        // });

                        // }

                        // //illness
                        // //console.log ("ok" , this.causali [ dayCur.idtypeactivity]);
                        //    if (dayCur.hour_activity>0) {

                        //     console.log("fesilnesst");
                        //     strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                        //     todos.push({
                        //       description: "Assenze "  /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        //       dates: [strDay],
                        //       color: "red",
                        //       isComplete: false,
                        //     });
                        //     //   datesOk.push ( strDay );
                        //     //  descOk.push ( dayCur.desc_festività );
                        //   }

                        //   if (dayCur.is_festivo) {
                        //     console.log("fest");
                        //     strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                        //     todos.push({
                        //       description: dayCur.desc_festività,
                        //       dates: [strDay],
                        //       color: "red",
                        //       isComplete: false,
                        //     });
                        //     //   datesOk.push ( strDay );
                        //     //  descOk.push ( dayCur.desc_festività );
                        //   }
                        //   if (
                        //     dayCur.day_working == "NOT WORKING" &&
                        //     (dayCur.desc_day == "Sab" || dayCur.desc_day == "Dom")
                        //   ) {
                        //     strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                        //     todos.push({
                        //       description: "Non lavorativo",
                        //       dates: [strDay],
                        //       color: "blue",
                        //       isComplete: false,
                        //     });
                        //   }
                        // }
                        // this.todos = todos;

                        /* this.todos = [
                        {
                            description: descOk ,
                            isComplete: false,
                            dates: datesOk,
                            color: 'red',
                        },
                        ]; */
                        //End

                        //move top
                        //this.monthArray = arrTimesheet;

                        //console.log(res.data); // Here I get what I need
                        //this.pigeons = res.data;


                        if (this.currAzienda != "") {

                            $("#res_full_name").html("<b>" + this.currAzienda + "</b>");


                        }
                        else {

                            this.loadAziendaUserLogged();

                        }






                        setTimeout(() => {

                            this.funcEvent();

                        }, 300);



                    });
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            console.log(response);

            /*       try {
              console.log(response);
             // arrTimesheet = response.data.Timesheet ;
              this.monthArray = arrTimesheet;
            } catch (error) {
              console.log(error  );
            } */

            //  this.data.days = response.data ;

            this.resetSumDay();
            this.updateSumTotals();

            //Setup status at top
            this.setupNavTop();

            //if ( !this.setupNavBottomDone)
            this.setupNavBottom();

        },
        pageChange(obj) {

            /*  if (   this.$root.$children[0].menuAziende  )
               {
                       this.dipendenti =  this.$root.$children[0].dipendenti;
                       this.aziende =  this.$root.$children[0].aziende;

               } */

            this.resetDay();

            this.monthArray = [];
            this.dayFormatted = "";
            // console.log(obj);
            this.curYear = obj.year;
            this.curMonth = obj.month;
            this.forecast[0].quantity = 0;
            this.forecast[1].quantity = 0;
            this.forecast[2].quantity = 0;
            this.forecast[3].quantity = 0;
            this.forecast[4].quantity = 0;

            this.entrata1 = "";
            this.entrata2 = "";
            this.uscita1 = "";
            this.uscita2 = "";

            this.day_editable = false;

            this.syncMonth();

            //this.$refs.defCalendar.$emit('refetchEvents');
            //  this.$root.defCalendar.$emit("dayclick");

            setTimeout(() => {
                // this.onDayClick(   {"day":16 , "ariaLabel": "" , "id":"2022-01-17" } );

            }, 4000);

            //enable

        },

        onDayClick(day) {

            this.curDay = day.day;

            this.forecast[0].quantity = 0;
            this.forecast[1].quantity = 0;
            this.forecast[2].quantity = 0;
            this.forecast[3].quantity = 0;
            this.forecast[4].quantity = 0;

            this.entrata1 = "";
            this.entrata2 = "";
            this.uscita1 = "";
            this.uscita2 = "";

            //const idx = this.days.findIndex(d => d.id === day.id);
            console.log(day);
            // this.forecast[0].day = day.ariaLabel;
            if (day) this.dayFormatted = day.ariaLabel;

            try {
                var ord = this.monthArray[day.day].hour_ordinario;
                var straord = this.monthArray[day.day].hour_straordinario;
                var assenza = this.monthArray[day.day].hour_activity;
                var idtypeactivity = this.monthArray[day.day].idtypeactivity;

                console.log("EX", this.monthArray[day.day].entrata1);

                this.entrata1 = this.monthArray[day.day].entrata1.substr(0, 5);
                this.entrata2 = this.monthArray[day.day].entrata2.substr(0, 5);
                this.uscita1 = this.monthArray[day.day].uscita1.substr(0, 5);
                this.uscita2 = this.monthArray[day.day].uscita2.substr(0, 5);


                //alert (this.entrata1);

                this.day_editable = Boolean(this.monthArray[day.day].day_editable);
                this.forecast[0].quantity = ord;
                this.forecast[1].quantity = straord;
                this.forecast[2].quantity = assenza;
                this.forecast[3].quantity = idtypeactivity;

                this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);
            } catch (error) {
                console.log(error);
            }
        },
    },

    data() {
        var today = new Date();
        var curMonth = today.getMonth() + 1;
        var curYear = today.getFullYear();
        var curDay = today.getDate();



        // console.log ( curYear);

        //var datesOk = [new Date("2021-12-12"), new Date("2021-12-13")];

        /*    var todos = [
             {
               description: "Festivity",
               isComplete: false,
               dates: datesOk, // Every Friday
               color: "red",
             },
           ]; */
        var todos = [];
        /* console.log(dates); */
        return {

            isDipEbadge: 0,
            entrata1: "",
            entrata2: "",
            uscita1: "",
            uscita2: "",

            curResource: "",

            itemsEbadge: ['Totali', 'Ebadge',],
            tab: null,
            menuAziende: false,
            search: '',
            searchDipendenti: '',
            todos,
            globalArrAziende: {},
            setupNavBottomDone: false,
            currDipendente: "",
            currDipendenteObj: {},
            currAzienda: "",
            su: 'N',
            aa: 'N',
            sheet: false,

            allStatus: [{
                img: 'keep.png',
                title: 'Aperto',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },
            {
                img: 'hangouts.png',
                title: 'For approval',
                "id": 2,
                icon: "mdi-account-arrow-right",
                "color": "yellow"
            },
            {
                img: 'inbox.png',
                title: 'Chiuso',
                "id": 3,
                icon: "mdi-account-lock",
                "color": "green"
            },
            ],
            tiles: [{
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inboex'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Meessenger'
            },
            {
                img: 'google.png',
                title: 'Gooegle+'
            },
            {
                img: 'keep.png',
                title: 'Keeep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'K3eep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hango3uts'
            },
            {
                img: 'messenger.png',
                title: 'Messen3ger'
            },
            {
                img: 'google.png',
                title: 'Google3+'
            },
            ],

            admindialog: false,
            sheetStatus: false,
            sheetAziende: false,
            sheetDipendenti: false,
            /*  curDayReadOnly:true, */
            totPresenzaMese: "0",
            totAssenzaMese: "0",
            totStraordinariMese: "0",
            causali: [],
            aziende: [],
            dipendenti: [],
            day_editable: false,
            curStatus: "Open",
            piano_orario: {},
            colorsStati: {
                "Close": "green",
                "For approval": "yellow",
                "Open": "red"
            },

            datesPoint: [new Date("2021-12-12"), new Date("2021-12-13")],
            showSpinner: false,
            curMonth: curMonth,
            curYear: curYear,
            curDay: curDay,

            monthArray: [],
            labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
            time: 0,
            itemsQuantity: [
                "0",
                "0.5",
                "1",
                "1.5",
                "2",
                "2.5",
                "3",
                "3.5",
                "4",
                "4.5",
                "5",
                "5.5",
                "6",
                "6.5",
                "7",
                "7.5",
                "8",
                "8.5",
                "9",
                "9.5",
                "10",
            ],
            forecast: [{
                day: "Ordinari",
                icon: "mdi-account-hard-hat",
                quantity: "0"
            },
            {
                day: "Straordinari",
                icon: "mdi-account-plus",
                quantity: "0"
            },
            {
                day: "Assenza",
                icon: "mdi-account-minus",
                quantity: "0"
            },
            {
                day: "Causale",
                icon: "mdi-star-minus-outline",
                quantity: "Festività",
            },
            {
                day: "Totale",
                icon: "mdi-expand-all",
                quantity: "8"
            },
            ],

            dayFormatted: "",
            dayAbout: "San Cristoforo",

            attributesOld: [{
                dot: {
                    style: {
                        "background-color": "brown",
                    },
                },
                dates: this.datesPoint
                /*  [
                                   new Date( '2021-12-12'), // Jan 12th
                                   new Date('2021-12-13'), // Jan 26th
                                   new Date(2018, 0, 15), // Jan 15th
                               ] */
                ,
            },

                /*  {
                        dot: "red",
                        dates: this.datesPoint,
                        }, */
            ],

            /*   date: new Date(), */
            date: "",

            /*   "it" : it,
            en: en,
            es: es,
            de: de */
        };
    },

    computed: {

        // filteredDipendenti() {
        //     return _.orderBy(this.dipendenti.filter(item => {
        //         if (!this.searchDipendenti) return this.dipendenti;
        //         return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
        //     }), 'headline')
        // },


        filteredDipendenti() {

            var elements = _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.full_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline');

            console.log("ELEMENTS: ", elements);

            var arrDip = [];
            var n_row = 1;

            for (var dip of elements) {

                dip.n_dip = n_row;

                n_row = n_row + 1;

                arrDip.push(dip);

            }


            return arrDip;
        },



        filteredAziende() {

            console.log("AZI 1: ", this.aziende);

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        attributes() {
            var ok = 0;
            console.log(ok);
            return [

                // Attributes for todos
                ...this.todos.map((todo) => ({
                    dates: todo.dates,
                    dot: todo.dot ? {
                        color: todo.color,
                        class: todo.isComplete ? "opacity-75" : "",
                    } : false,
                    bar: todo.bar ? todo.bar : false,
                    popover: {
                        label: todo.description,
                        hideIndicator: false,
                    },
                    customData: todo,
                })),
            ];
        },
    },



    components: {
        /*   Datepicker, */

        datepicker,
        RiseLoader,

    },
    // ...
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.cl_res_full_name {
    padding-left: 8px;
    padding-top: 2px;
}

#timesheet .move_dip {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: absolute !important;
    min-width: 100% !important;
    left: 0 !important;
    transform: translate(0px, 14px) !important;
    margin-top: 22px;
}



.v-list-item {
    margin-left: 20px;
}

select:focus-visible {
    outline: 1px dashed rgb(247, 247, 247);
}

.v-select__slot {
    font-size: 10px;
}

.v-list-item__title {
    font-size: 12px;
}

.vc-day {
    min-height: 36px !important;
    padding: 8px;
}

.contQuanties {
    max-width: 420px !important;
}

@media only screen and (max-width: 600px) {

    .cl_res_full_name {
        margin-left: 0px;
    }

    .v-menu__content REMOVE {
        left: 0px !important;
        min-width: 100vw !important;
        top: auto !important;
        bottom: 0px !important;
        position: fixed !important;
        min-height: 200px;
        /*   min-height: 300px; */

        background: #80808091;
        background: white;

        /*  pointer-events: none; */

    }

    .v-menu__content .v-select-listREMOVE {
        max-height: 100%;
        bottom: 0px;
        position: absolute;
        overflow: auto;
        min-width: 100vw;
    }

    .vc-day {
        min-height: 2px !important;
        padding: 6px 8px;
    }

    .contQuanties {
        max-width: 320px !important;
    }
}

span.titletotals {
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
}

#timesheet .totals {
    transform: translate(0px, 78px);
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    font-size: 12px;
    margin-top: 17px;
}

.contDayFormatted {}

.dayFormatted {
    color: black !important;
    font-weight: bolder;
}

.spanStatus {
    color: red;
}

.rowStatus {
    /*  padding-top:20px;
        padding-bottom:20px;
        font-weight: bolder;
        margin-bottom:200px; */
    /* transform: translate(0px, -60px); */
    display: none;
}

.progressBar {
    position: fixed;
    top: 25%;
    left: 25%;
    position: fixed;
    justify-content: center;
    display: flex;
    right: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    top: 0;
}

.contcalendar .v-card__actions {
    padding: 0px !important;
    padding-top: 0px !important;
}

.nocolor {
    border: none !important;
    box-shadow: none !important;
}

#timesheet {
    padding-top: 0px;
    max-width: 90vw;
    margin: 0 auto;
}

.contcalendar {
    min-width: 60vw;
    /*
  min-height: 60vh; */
    align-content: center;
}

.vc-container {
    --day-content-height: 50px;
    --day-content-width: 50px;
}

.vc-container.vc-is-expanded {
    border: none;
}

/* .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid #d6d6d6 !important ;
}

.vdp-datepicker__calendar {
  width: auto;
  max-width: 300px;
  border: none !important;
}
.vdp-datepicker {
  margin: 0 auto;
  max-width: 300px;
}
.vdp-datepicker__calendar .cell.selected,
.vdp-datepicker__calendar .cell.selected:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  background: #007b30 !important;
  border-color: none;
} */

.selectHour {
    padding-left: 25px;
    font-size: 12px !important;
}

.v-list-item {
    min-height: 42px;
}

.v-list-item__icon {
    margin: 10px 0px;
}

.totaleCombo input {
    color: black !important;
    font-weight: 600;
}

html:not(.hydrated) body {
    display: block;
}

.vc-header {
    font-family: 'Montserrat' !important;

}

#timesheet .plus,
#timesheet .minus {
    background: #007b30;
    border: 1px solid;
    padding: 0px 4px;
    cursor: pointer;
    max-height: 24px;
    min-width: 24px;
    text-align: center;

}

#timesheet .plus {
    margin-right: 6px;
}

.addbuttons {
    display: flex;
    margin-left: 5px;
    align-items: center;
    font-size: 20px;
    padding-bottom: 12px;

    /* border: 1px solid; */
}

.conSelect {
    display: flex;

}

div[disabled] {
    opacity: 0.2;
    cursor: default;
}
</style>

<style>
.cl_cont_azi_res_ts {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    left: 0px;
    min-width: 100%;
    padding-top: 3px !important;
    background-color: white;
    border: solid 1px #007b30;
    min-height: 70px !important;
}


input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.detailtotals {
    display: none;
}

.vc-bars {
    max-width: 18px;
    transform: translate(0px, 5px);
}

.vc-bar {
    max-height: 1px !important;
}

.v-application .text-center {

    overflow: auto;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: inherit !important;
}

.v-btn--disabled {
    opacity: 0.3;
}

.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
}

.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.closeStatusSheet {
    position: absolute;
    right: 0;
}

.v-dialog__content {
    margin-top: 56px;
}
</style>
