<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_perform_risorse">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall' class='checkall' slot='start' style="width:10px!important;height:10px!important">
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Cognome</th>
                                        <th class='all'>Nome</th>
                                        <th class='desktop' style="text-align:center">Stato</th>
                                        <th class='desktop' style="text-align:center">Ordinario</th>
                                        <th class='desktop' style="text-align:center">Eccedenze</th>
                                        <th class='desktop' style="text-align:center">Assenza 1</th>
                                        <th class='desktop' style="text-align:center">Totale</th>

                                        <th class='all' style="text-align:center">%</th>

                                        <th class='all' style="text-align:center">Performance</th>


                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="11">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetChangeStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetChangeStatus = !sheetChangeStatus">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatus" :key="tile.title" @click="changeStatusAdmin(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                        tile.title
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetStatus">
                <v-sheet class="text-center" height="385px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatusFilter" :key="tile.title" @click="changeStatus(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                        tile.title
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>



        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-icon aria-hidden="false">mdi-domain</v-icon>

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>


                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopuppdfreport" style="display:none">

            <template>
                <modal name="popupPdfReport" :clickToClose="false" :width="300" :height="300">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row style="padding-left:12px!important;padding-right:12px!important">

                        <v-col cols="12" md="12">

                            <div id="cont_year" style="cursor:pointer">
                                <ion-item id="itemYear"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Da</ion-label>

                                    <ion-select id="from_month" name="from_month" class='from_month'
                                        cancel-Text="Chiudi" done-Text="" :value="selMeseDa" interface="action-sheet">

                                        <ion-select-option v-for="item in mesi" :key="item.value" :value='item.value'>
                                            {{
                                            item.description
                                            }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>


                        </v-col>

                    </v-row>

                    <v-row style="padding-left:12px!important;padding-right:12px!important">

                        <v-col cols="12" md="12">

                            <div id="cont_year" style="cursor:pointer">
                                <ion-item id="itemYear"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">A</ion-label>

                                    <ion-select id="to_month" name="to_month" class='to_month' cancel-Text="Chiudi"
                                        done-Text="" :value="selMeseA" interface="action-sheet">

                                        <ion-select-option v-for="item in mesi" :key="item.value" :value='item.value'>
                                            {{
                                            item.description
                                            }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>


                        </v-col>

                    </v-row>





                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-report" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-confirm-report" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer"
                                    transition="scale-transition" title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



    </div>


</template>

<script>
import router from ".././router";
import apirisorse from "../utils/risorse/apirisorse";
import apiusers from "../utils/users/apiusers";
import apitimesheet from "../utils/timesheet/apitimesheet";


import {
    bus
} from "../main";
//import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        this.loadLogo();

        var pointerVue = this;

        // default value 
        //window.curCompany = "-1";

        var curYear = new Date().getFullYear();
        var curMonth = new Date().getMonth() + 1;

        console.log("CUR YEAR: ", curYear);

        console.log("CUR MONTH: ", curMonth);

        window.curYear = curYear;
        window.curMonth = curMonth;

        window.curStatus = 3; // default chiusi


        window.curSrcText = "";


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '.selaggiorna', function () {

                console.log(("EVENTO CLICK SELAGGIORNA"));

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });



            $('body').on('click', '.selstato', function () {

                console.log(("EVENTO CLICK SELSTATO"));

                pointerVue.sheetStatus = true;

            });


            $('body').on('click', '.btn-cancel-report', function () {

                console.log(("EVENTO CLICK SELSTATO"));

                pointerVue.$modal.hide('popupPdfReport');

            });

            $('body').on('click', '.btn-confirm-report', function () {

                console.log(("EVENTO CLICK SELSTATO"));


                console.log("MESE DA:", $("#from_month").val());
                console.log("MESE A:", $("#to_month").val());

                var errore = "";

                if ($("#from_month").val() == "") {
                    errore = "Specificare valore dal";
                }

                if (errore == "") {
                    if ($("#to_month").val() == "") {
                        errore = "Specificare valore al";
                    }

                }



                if (errore == "") {


                    pointerVue.createReportPDF();



                }
                else {

                    pointerVue.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }






            });








            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.seldata', function (event) {
                console.log(event);


                $(".contpopupdatefrom").show();

                pointerVue.$modal.show('popupDateFrom');



            });

            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataDa = null;
                pointerVue.dataA = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);

                window.dataDa = pointerVue.dataDa;
                window.dataA = pointerVue.dataA;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();


            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });




        });




        setTimeout(() => {

            this.syncAziende();

        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.delMsg();

                    break;

                case "btn_changestatusadmin":

                    // console.log("CURR ITEM: ", this.currItem.stato);

                    this.sheetChangeStatus = true;

                    break;

                case "btn_send_approvation":

                    // this.sendInApprovation();

                    break;





                case "btn_exit":
                    // router.push({
                    //     path: "/dash"
                    // });
                    break;

                case "btn_add":
                    // router.push({
                    //     path: "/editRisorsa/0"
                    // });
                    break;

                case "btn_edit":

                    var nome = "";
                    var cognome = "";
                    var anno = "";
                    var mese = "";
                    var azienda = "";
                    var login = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            nome = $(this).attr("name-res-sel");
                            cognome = $(this).attr("surname-res-sel");
                            anno = $(this).attr("anno-sel");
                            mese = $(this).attr("mese-sel");
                            azienda = $(this).attr("name-azi-sel");
                            login = $(this).attr("login-user-sel");

                        }

                    });

                    console.log("NOME:", nome);
                    console.log("COGNOME:", cognome);
                    console.log("ANNO:", anno);
                    console.log("MESE:", mese);
                    console.log("AZIENDA:", azienda);
                    console.log("LOGIN:", login);


                    window.$cookies.set("sel_cognome_nome_ver_ts", cognome + " " + nome, "9y");
                    window.$cookies.set("sel_anno_ver_ts", anno, "9y");
                    window.$cookies.set("sel_mese_ver_ts", mese, "9y");
                    window.$cookies.set("sel_azienda_ver_ts", azienda, "9y");
                    window.$cookies.set("sel_login_ver_ts", login, "9y");


                    router.push({
                        path: "/editVerificaTimesheet"
                    });




                    break;

                case "btn_back":


                    router.push({
                        path: "/filterPerformanceTimesheet"
                    });



                    break;

                case "btn_report":


                    setTimeout(() => {

                        $(".contpopuppdfreport").show();

                        this.$modal.show('popupPdfReport');

                    }, 300);


                    break;



                default:
                    break;

            }
        });


        // btn_send_approvation


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));


        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        textSearch: "",

        curSelRow: null,

        dataDa: null,
        showPicker3: false,

        selMeseDa: null,
        selMeseA: null,

        dataA: null,
        showPicker4: false,

        aziende: [],

        mesi: [
            { value: 1, description: "Gennaio" },
            { value: 2, description: "Febbraio" },
            { value: 3, description: "Marzo" },
            { value: 4, description: "Aprile" },
            { value: 5, description: "Maggio" },
            { value: 6, description: "Giugno" },
            { value: 7, description: "Luglio" },
            { value: 8, description: "Agosto" },
            { value: 9, description: "Settembre" },
            { value: 10, description: "Ottobre" },
            { value: 11, description: "Novembre" },
            { value: 12, description: "Dicembre" }
        ],

        search: "",

        sheetAziende: false,


        allStatus: [{
            img: 'keep.png',
            title: 'Open',
            "id": 1,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'For approval',
            "id": 2,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Closed',
            "id": 3,
            icon: "mdi-account-lock",
            "color": "green"
        },
        ],


        allStatusFilter: [{
            img: '',
            title: 'Tutti',
            "id": 1,
            icon: "mdi-table",
            "color": "black"
        },
        {
            img: 'keep.png',
            title: 'Refused',
            "id": 2,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'to Approve',
            "id": 3,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Approved',
            "id": 4,
            icon: "mdi-account-lock",
            "color": "green"
        },

        {
            img: 'inbox.png',
            title: 'Open',
            "id": 5,
            icon: "mdi-account-lock-open",
            "color": "orange"
        },

        ],




        tiles: [{
            img: 'keep.png',
            title: 'Keep'
        }],



        sheetChangeStatus: false,
        sheetStatus: false,

        currItem: { stato: "" },


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,





    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },

    methods: {

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        createReportPDF: async function () {

            var that = this;

            that.$modal.hide('popupPdfReport');

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });




            console.log("IDS SEL:", ids);
            console.log("MESE DA SEL:", $("#from_month").val());
            console.log("MESE A SEL:", $("#to_month").val());



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apitimesheet.createPerformancePDF(
                ids,
                $("#from_month").val(),
                $("#to_month").val(),
                v_token,
                window.curCompany
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createPerformancePDF", res);

                window.open(res.data.Url, "_blank");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },



        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "emtool")
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;

                        // window.$cookies.set("user_models", res.data.Models, "9y");



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },






        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },



        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getFilterAziende(
                v_token,
                "emt"
            ).then((res) => {

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                console.log("res from getFilterAziende", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        changeStatus: async function (status) {

            console.log(status);

            this.sheetStatus = false;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.curStatus = status;

            this.hideShowButtons(false);

            window.table.ajax.reload();


        },


        delMsg: function async() {
            var token = window.$cookies.get("token");
            var that = this;

            this.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: "Vuoi eliminare i timesheet selezionati ? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var v_anno = window.curYear;
                        var v_mese = window.curMonth;

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("id-user-sel");
                            }

                        });

                        console.log("TOKEN:", token);
                        console.log("ANNO:", v_anno);
                        console.log("MESE:", v_mese);
                        console.log("IDS SEL:", ids);


                        //start request
                        var response;

                        try {

                            that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            response = await apitimesheet.multiDelete(
                                token,
                                window.curYear,
                                window.curMonth,
                                ids
                            ).then((res) => {

                                that.$root.$children[0].showProgress = false;
                                console.log("res from multiDelete", res);

                                that.$swal({
                                    icon: "success",
                                    text: "Timesheet eliminati correttamente",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }).catch(err => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err);
                                var msg = err.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                                console.log("response", response);

                            }

                            );


                        } catch (error) {
                            that.$root.$children[0].showProgress = false;
                            console.log(error);

                        }
                        console.log(response);


                    }
                });



        },

        changeStatusAdmin: async function (status) {

            console.log(status);

            this.sheetChangeStatus = false;

            var that = this;

            var v_token = window.$cookies.get("token");

            var v_year = window.curYear;
            var v_month = window.curMonth;

            var statusInt = 0;

            if (status == "Open") statusInt = 1;
            if (status == "For approval") statusInt = 2;
            if (status == "Closed") statusInt = 3;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("id-user-sel");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("CUR YEAR:", v_year);
            console.log("CUR MONTH:", v_month);
            console.log("VAL STATUS:", statusInt);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apitimesheet.updateMultiStatus(
                v_token,
                window.curYear,
                window.curMonth,
                ids,
                statusInt
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateMultiStatus", res);

                that.$swal({
                    icon: "success",
                    text: "Stati aggiornati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();


                    window.table.ajax.reload();

                    that.hideShowButtons(false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        changeStatusAdminOld: async function (status) {

            //  this.currFilterStatus = status;

            console.log(status);


            //START
            var token = window.$cookies.get("token");

            /*   var username = window.$cookies.get("username");
              var token_system = window.$cookies.get("token_system"); */
            //var API_URL = "https://services.ebadge.it/public/api/sendReqHoliday";

            //var API_URL = "https://app.emtool.eu/api/acceptRejectHolidaysPermits";

            var API_URL = this.getUrlDomainApi("acceptRejectMultiHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            var operation = "";
            if (status == "Refused") operation = 'reject';
            if (status == "to Approve") operation = 'to Approve';
            if (status == "Approved") operation = 'accept';
            if (status == "Open") operation = 'open';

            console.log("STATUS: ", status);
            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("OPERATION: ", operation);
            console.log("ID_RICHIESTA: ", ids);

            var that = this;
            that.$root.$children[0].showProgress = true;

            /* eslint-disable no-unreachable */
            /*    return; */
            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,
                    'operation': operation,
                },
                success: function (resultData) {

                    that.$root.$children[0].showProgress = false;

                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    that.sheetChangeStatus = false;

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                },
                error: function (errorData) {

                    that.sheetChangeStatus = false;

                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.showSpinner = false;

                }
            });
            //END

        },



        sendInApprovation: function () {


            var that = this;
            var token = window.$cookies.get("token");

            var API_URL = this.getUrlDomain("sendMultiRequestHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);




            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("id_richiesta: ", ids);

            that.$root.$children[0].showProgress = true;

            $.ajax({
                type: "GET",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,

                },
                success: function (resultData) {
                    that.$root.$children[0].showProgress = false;
                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    that.hideShowButtons(false);

                    window.table.ajax.reload();


                },
                error: function (errorData) {
                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.$root.$children[0].showProgress = false;

                }
            });


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            var filtri = "";

            if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                filtri = `<img src='/emt/img/refresh-emt.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/emt/img/aziende-emt.png' style='border:0!important;' class='selazienda' title='Aziende' /><div style="transform: translateY(-1px);margin-left:7px" title='Anno'><ion-select data-id="1" value="` + window.curYear + `" class="selanno" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Anno'><ion-select-option value='2020'>2020</ion-select-option><ion-select-option value='2021'>2021</ion-select-option><ion-select-option value='2022'>2022</ion-select-option><ion-select-option value='2023'>2023</ion-select-option></ion-select></div><div style="transform: translateY(-1px);margin-left:7px" title='Mese'><ion-select data-id="1" value="` + window.curMonth + `" class="selmese" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Mese'><ion-select-option value='1'>Gennaio</ion-select-option><ion-select-option value='2'>Febbraio</ion-select-option><ion-select-option value='3'>Marzo</ion-select-option><ion-select-option value='4'>Aprile</ion-select-option><ion-select-option value='5'>Maggio</ion-select-option><ion-select-option value='6'>Giugno</ion-select-option><ion-select-option value='7'>Luglio</ion-select-option><ion-select-option value='8'>Agosto</ion-select-option><ion-select-option value='9'>Settembre</ion-select-option><ion-select-option value='10'>Ottobre</ion-select-option><ion-select-option value='11'>Novembre</ion-select-option><ion-select-option value='12'>Dicembre</ion-select-option></ion-select></div> `;


            }
            else {

                filtri = `<div style="transform: translateY(-1px);margin-left:7px" title='Anno'><ion-select data-id="1" value="` + window.curYear + `" class="selanno" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Anno'><ion-select-option value='2020'>2020</ion-select-option><ion-select-option value='2021'>2021</ion-select-option><ion-select-option value='2022'>2022</ion-select-option><ion-select-option value='2023'>2023</ion-select-option></ion-select></div><div style="transform: translateY(-1px);margin-left:7px" title='Mese'><ion-select data-id="1" value="` + window.curMonth + `" class="selmese" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Mese'><ion-select-option value='1'>Gennaio</ion-select-option><ion-select-option value='2'>Febbraio</ion-select-option><ion-select-option value='3'>Marzo</ion-select-option><ion-select-option value='4'>Aprile</ion-select-option><ion-select-option value='5'>Maggio</ion-select-option><ion-select-option value='6'>Giugno</ion-select-option><ion-select-option value='7'>Luglio</ion-select-option><ion-select-option value='8'>Agosto</ion-select-option><ion-select-option value='9'>Settembre</ion-select-option><ion-select-option value='10'>Ottobre</ion-select-option><ion-select-option value='11'>Novembre</ion-select-option><ion-select-option value='12'>Dicembre</ion-select-option></ion-select></div>`;


            }




            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.curCompany = window.$cookies.get("sel_filter_azienda_verifica_ts");




                // window.curCompany = v_company;
                // window.curStatus = "S"; // in forza

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'surname',
                        orderable: false,
                        name: 'surname',
                        width: '4%'
                    },

                    {
                        data: 'name',
                        orderable: false,
                        name: 'name',
                        width: '4%'
                    },

                    {
                        data: 'description',
                        orderable: false,
                        name: 'description',
                        width: '4%'
                    },

                    {
                        data: 'hour_ordinario',
                        orderable: false,
                        name: 'hour_ordinario',
                        width: '4%'
                    },


                    {
                        data: 'hour_straordinario',
                        orderable: false,
                        name: 'hour_straordinario',
                        width: '4%'
                    },

                    {
                        data: 'hour_activity',
                        orderable: false,
                        name: 'hour_activity',
                        width: '4%'
                    },

                    {
                        data: 'total_hour',
                        orderable: false,
                        name: 'total_hour',
                        width: '4%'
                    },

                    {
                        data: 'percentuale',
                        orderable: false,
                        name: 'percentuale',
                        width: '2%'
                    },


                    {
                        data: 'andamento',
                        orderable: false,
                        name: 'andamento',
                        width: '2%'
                    },



                ];


                $(".spin").show();




                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("getResourceTimesheetPerformanceApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.dataDa = window.dataDa;
                            valori.dataA = window.dataA;

                            valori.curYear = window.curYear;
                            valori.curMonth = window.curMonth;



                            valori.curSrcText = window.curSrcText;


                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);






                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("getResourceTimesheetPerformanceApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Performance Risorse</div>
                       ` + filtri + `

                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });


                $('body').on('click', '.cont_perform_risorse tr', function () {

                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        console.log("DATA ROW: ", row_data);

                        pointerVue.curSelRow = row_data;


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        //$("tr").removeClass("active-row");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });



                $(".selanno").on("ionChange", function (opt) {
                    console.log(opt);

                    console.log("VALUE ANNO: ", this.value)

                    window.curYear = this.value;

                    pointerVue.hideShowButtons(false);

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();



                });

                $(".selmese").on("ionChange", function (opt) {
                    console.log(opt);

                    console.log("VALUE MESE: ", this.value)

                    window.curMonth = this.value;

                    pointerVue.hideShowButtons(false);

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();



                });

                $(".selstato2").on("ionChange", function (opt) {
                    console.log(opt);

                    console.log("VALUE STATO: ", this.value)

                    window.curStatus = this.value;

                    pointerVue.hideShowButtons(false);

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();



                });





                window.parentiAddedEvents = true;

            });

        },


        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tot_open_status = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                    var val_status_id = $(this).attr("status-id");

                    if (val_status_id == "Open") // stato aperto
                    {

                        tot_open_status = tot_open_status + 1;

                    }



                }

            });

            console.log("TOT SEL: ", tot);
            console.log("TOT SEL OPEN STATUS: ", tot_open_status);

            setTimeout(() => {


                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                var pulsantis = [];


                if (tot == 1) {


                    pulsantis.push(

                        {
                            text: "Report",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/pdf-emt.png",
                            link: "/info",
                            id: "btn_report",
                            disabled: false,
                            title: "Report PDF",
                            width: 30
                        });


                }



                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {



                    pulsantis.push(

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                            link: "/confOrariChoice",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            width: 35
                        }



                    );




                }



                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },






        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                console.log(v_enable);


                var pulsantis = [];

                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                    pulsantis.push(

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                            link: "/confOrariChoice",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            width: 35
                        }



                    );


                }



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        // createPdf: async function () {

        //     var that = this;

        //     that.showSpinner = true;
        //     that.$root.$children[0].showProgress = true;

        //     // valori.currDayTo = window.currDayTo;
        //     // valori.curStatus = window.curStatus;
        //     // valori.curPiano = window.curPiano;

        //     var response = await apibookingrsa.getPdfPrenotazioni(
        //         "ZmFnb3N0b25p",
        //         3165,
        //         window.currDay,
        //         window.currDayTo,
        //         window.curStatus,
        //         window.curPiano,
        //         ""
        //     ).then((res) => {

        //         that.$root.$children[0].showProgress = false;
        //         console.log("res from getPdfPrenotazioni", res);

        //         try {

        //             console.log(res.data.Result);

        //             window.open(res.data.Result, '_blank');

        //         } catch (error) {
        //             console.log(error);
        //         }

        //     }).catch(err => {
        //             that.$root.$children[0].showProgress = false;
        //             console.log(err);
        //             var msg = err.response.data.Error;

        //             that.$swal({
        //                 icon: "error",
        //                 text: msg,
        //                 showConfirmButton: false,
        //                 timer: 8000
        //             });
        //             console.log("Errori", "Non è stato possibile esportare il pdf");
        //             console.log("response", response);

        //         }

        //     );

        // },

        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apirisorse.deleteRisorsa(
                            window.id_par_sel,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteRisorsa", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #007b30;

}

.pay a {
    color: #007b30;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #007b30;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_perform_risorse #myTable {
    margin-right: 3px;
}

.cont_perform_risorse table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_perform_risorse table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-bottom: 9px !important;
}

.cont_perform_risorse th {
    padding-left: 5px !important;
}

.cont_perform_risorse ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_perform_risorse .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_perform_risorse #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #007b30;

}

.cont_perform_risorse div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_perform_risorse #myTable_wrapper {
    padding-top: 0px;
}

.cont_perform_risorse .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_perform_risorse .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_perform_risorse .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}


.cont_perform_risorse .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.closeStatusSheet {
    position: absolute;
    right: 0;
}

.selstato {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.selreport {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_perform_risorse #myTable {
    max-width: 95vw !important;
    margin-right: 0px;
    min-width: 95vw !important;
    position: relative;
    width: 95vw !important;

}

.cont_perform_risorse .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_perform_risorse .selaggiorna {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.cont_perform_risorse .clDataDa {
    font-weight: bold;
    transform: translate(6px, -12px);
}

.cont_perform_risorse .clValueDataDa {
    font-weight: bold;
    transform: translate(11px, -11px);
}

.cont_perform_risorse .seldata {
    max-width: 22px;
    min-height: 22px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: --2px;
}


.cont_perform_risorse .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_perform_risorse .checkall {
    width: 13px;
    height: 13px;
}


.cont_perform_risorse .selanno {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 24px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/calendario-giorni-emt.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_perform_risorse .selmese {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 24px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/calendario-mesi-emt.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}


.cont_perform_risorse .selstato2 {
    max-width: 24px !important;
    min-height: 24px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 24px !important;
    text-indent: -119px !important;
    background-size: 24px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/Filtro.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_perform_risorse .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_perform_risorse .val_status {
    width: 10px !important;
}



.cl-ordinario {
    width: 100% !important;
    text-align: center !important
}

.cl-ordinario-green {
    width: 100% !important;
    text-align: center !important;
    border: 2px solid forestgreen;
    min-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    margin-top: -3px;
}

.cl-straordinario {
    width: 100% !important;
    text-align: center !important
}

.cl-straordinario-blue {
    width: 100% !important;
    text-align: center !important;
    border: 2px solid dodgerblue;
    min-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    margin-top: -3px;
}


.cl-activity {
    width: 100% !important;
    text-align: center !important
}

.cl-activity-red {
    width: 100% !important;
    text-align: center !important;
    border: 2px solid red;
    min-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    margin-top: -3px;
}


.cl-total {
    width: 100% !important;
    text-align: center !important
}

.p_total_hour,
.p_total_hour_worked,
.p_total_hour_office,
.p_total_hour_smartworking {
    width: 100% !important;
    text-align: center !important
}

.cl-progetti {
    text-align: center;
}

.cl-andamento {
    text-align: center;
}

@media screen and (max-width: 960px) {

    .cl_note {
        display: inline;
    }

    .cont_perform_risorse .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_perform_risorse #myTable {
        /* margin-top:54px; */
    }

    .cont_perform_risorse #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_perform_risorse #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_perform_risorse #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_perform_risorse .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_perform_risorse #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_perform_risorse .cl_num {
        margin-left: -3px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_perform_risorse .cl_checkall {
        padding-left: 19px;
        padding-top: 2px;
    }


    .cl-ordinario {
        width: 100% !important;
        text-align: center !important;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-ordinario-green {
        width: 100% !important;
        text-align: center !important;
        border: 2px solid forestgreen;
        min-height: 20px;
        vertical-align: middle;
        padding-top: 3px;
        margin-top: -3px;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-straordinario {
        width: 100% !important;
        text-align: center !important;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-straordinario-blue {
        width: 100% !important;
        text-align: center !important;
        border: 2px solid dodgerblue;
        min-height: 20px;
        vertical-align: middle;
        padding-top: 3px;
        margin-top: -3px;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-activity {
        width: 100% !important;
        text-align: center !important;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-activity-red {
        width: 100% !important;
        text-align: center !important;
        border: 2px solid red;
        min-height: 20px;
        vertical-align: middle;
        padding-top: 3px;
        margin-top: -3px;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }


    .cl-total {
        width: 100% !important;
        text-align: center !important;
        display: inline
    }

    .p_total_hour,
    .p_total_hour_worked,
    .p_total_hour_office,
    .p_total_hour_smartworking {
        width: 100% !important;
        text-align: center !important;
        display: inline
    }


    .cl-progetti {
        text-align: center;
        display: inline;
    }

}
</style>
